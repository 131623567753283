import { useTranslation } from 'react-i18next';

import Dayjs from 'dayjs';
import { twMerge } from 'tailwind-merge';

import { CheckIcon, XMarkIcon } from '@heroicons/react/24/outline';

import { Order } from 'features/order/models/Order';

import { ArrowRightIcon } from 'components/icon/outline';
import { InfiniteScrollTable } from 'components/ui';

import { formatPrice } from 'utils/formatting';

import { OrderStatusBadge } from '../OrderStatusBadge';

interface Props {
  order: Order;
  idx: number;
  isRowSelected: boolean;
  onRowSelected: (order: Order) => void;
  onDetailsButtonClick: (orderId: string, isDraft: boolean) => void;
}

const OrderListTableRow = ({
  order,
  idx,
  isRowSelected,
  onRowSelected,
  onDetailsButtonClick,
}: Props) => {
  const { t } = useTranslation();
  return (
    <InfiniteScrollTable.Tr
      className={twMerge(
        'hover:bg-gray-50 group',
        isRowSelected && 'bg-gray-50',
      )}
    >
      <InfiniteScrollTable.Td className="relative w-13">
        {
          isRowSelected ? (
            <div className="absolute inset-y-0 left-0 w-0.5 bg-primary-500" />
          ) : (
            <p className="group-hover:hidden text-center">
              {idx + 1}
            </p>
          )
        }

        <input
          type="checkbox"
          className={twMerge(
            'absolute left-1/2 -translate-x-1/2 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-primary-500 group-hover:block hidden',
            isRowSelected && 'block',
          )}
          value={order.id}
          checked={isRowSelected}
          onChange={() => onRowSelected(order)}
          onClick={(e) => e.stopPropagation()}
        />
      </InfiniteScrollTable.Td>
      <InfiniteScrollTable.Td>
        <div className="w-fit">
          <OrderStatusBadge status={order.status} />
        </div>
      </InfiniteScrollTable.Td>
      <InfiniteScrollTable.Td>
        {order.customer?.name ?? order.createdByUserInfo?.GetEmails().join(', ')}
      </InfiniteScrollTable.Td>
      <InfiniteScrollTable.Td className="!font-mono">
        {order.products.length}
      </InfiniteScrollTable.Td>
      <InfiniteScrollTable.Td className="!font-mono">
        {formatPrice(
          order?.totalPrice?.amount || 0,
          order?.totalPrice?.currency,
        )}
      </InfiniteScrollTable.Td>
      <InfiniteScrollTable.Td>
        {Dayjs(order.requestedDeliveryTime).format('llll')}
      </InfiniteScrollTable.Td>
      <InfiniteScrollTable.Td>
        {Dayjs(order.createdAt).format('llll')}
      </InfiniteScrollTable.Td>
      <InfiniteScrollTable.Td>
        {order.isExported ? (
          <CheckIcon
            width={24}
            height={24}
            className="text-success"
          />
        ) : (
          <XMarkIcon
            width={24}
            height={24}
            className="text-light-gray-100"
          />
        )}
      </InfiniteScrollTable.Td>
      <InfiniteScrollTable.Td isLast>
        {order.isDraft ? (
          <button
            type="button"
            className="inline-flex items-center gap-1 px-3 py-1 relative bg-purple-highlight-2 hover:bg-purple-highlight-1 rounded-[5px] shadow-blue-light-tint-drop-shadow"
            onClick={() => onDetailsButtonClick(order.id, true)}
          >
            <div className="relative w-fit mt-[-1.00px] font-font-screen-xs-medium font-[number:var(--font-screen-xs-medium-font-weight)] text-white text-[length:var(--font-screen-xs-medium-font-size)] tracking-[var(--font-screen-xs-medium-letter-spacing)] leading-[var(--font-screen-xs-medium-line-height)] whitespace-nowrap [font-style:var(--font-screen-xs-medium-font-style)]">
              {t('order.list.process')}
            </div>
            <ArrowRightIcon
              className="size-4 desktop:size-5 text-white"
            />
          </button>
        ) : (
          <button
            type="button"
            className="inline-flex items-center gap-1 px-3 py-1 relative bg-purple-highlight-2 hover:bg-purple-highlight-1 rounded-[5px] shadow-blue-light-tint-drop-shadow"
            onClick={() => onDetailsButtonClick(order.id, false)}
          >
            <div className="relative w-fit mt-[-1.00px] font-font-screen-xs-medium font-[number:var(--font-screen-xs-medium-font-weight)] text-white text-[length:var(--font-screen-xs-medium-font-size)] tracking-[var(--font-screen-xs-medium-letter-spacing)] leading-[var(--font-screen-xs-medium-line-height)] whitespace-nowrap [font-style:var(--font-screen-xs-medium-font-style)]">
              {t('order.list.view')}
            </div>
            <ArrowRightIcon
              className="size-4 desktop:size-5 text-white"
            />
          </button>
        )}
      </InfiniteScrollTable.Td>
    </InfiniteScrollTable.Tr>
  );
};

export default React.memo(OrderListTableRow);
