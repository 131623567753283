import { useCallback, useEffect, useState } from 'react';

import { PRODUCTS_MODEL_PATH } from 'features/instruction/constants';

import { Order, ProductWithQuantity } from '../../../models/Order';

import { ProductSortingColumn, SortExtractedProductsBy } from 'features/product/types/product';

import { setValueByPath } from 'features/instruction/utils';

type UseProductsReturnType = {
  updateProductByUiId: (uiId: string, values: any) => void;
  updateProductByUiIdAndPath: (uiId: string, path: string, val: any) => void;
  addNewProduct: (product: ProductWithQuantity) => void;
  removeProductByUiIdAndPositionId: (orderId: string, uiId: string, positionId: string) => void;

  sortingColumn: ProductSortingColumn | null;
  setSortingColumn: (sortingColumn: ProductSortingColumn | null) => void;
  filterEnabledProducts: boolean;
  setFilterEnabledProducts: (filterEnabledProducts: boolean) => void;
  sortExtractedProductsBy: SortExtractedProductsBy | null;
  setSortExtractedProductsBy: (sortExtractedProductsBy: SortExtractedProductsBy | null) => void;
};

interface UseProductsProps {
  deleteProductErrorsByUiId: (orderId: string, uiId: string) => void;
  removeKeywordsByFieldId: (id: string) => void;
  updateOrderByFn: (updateFn: (order: Order) => Order) => void;
}

export const useProducts = (props: UseProductsProps): UseProductsReturnType => {
  const {
    deleteProductErrorsByUiId,
    removeKeywordsByFieldId,
    updateOrderByFn,
  } = props;
  const [sortingColumn, setSortingColumn] = useState<ProductSortingColumn | null>(null);
  const [filterEnabledProducts, setFilterEnabledProducts] = useState<boolean>(false);

  const [sortExtractedProductsBy, setSortExtractedProductsBy] = useState<SortExtractedProductsBy | null>(null);

  const updateProductByUiId = useCallback((uiId: string, values: any) => {
    updateOrderByFn((order) => ({
      ...order,
      products: order.products.map((product) => (product.uiId === uiId ? { ...product, ...values } : product)),
      didChangeMade: true,
    }));
  }, [updateOrderByFn]);

  const updateProductByUiIdAndPath = useCallback((uiId: string, path: string, val: any) => {
    const _path = path.split(`${PRODUCTS_MODEL_PATH}.*.`)[1];

    updateOrderByFn((order) => ({
      ...order,
      products: order.products.map((product) => (product.uiId === uiId ? setValueByPath(product, val, _path) : product)),
      didChangeMade: true,
    }));
  }, [updateOrderByFn]);

  const addNewProduct = useCallback((product: ProductWithQuantity) => {
    updateOrderByFn((order) => ({
      ...order,
      products: [product, ...(order.products || [])],
      didChangeMade: true,
    }));

    setTimeout(() => {
      document.getElementById(product.uiId)?.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }, 200);
  }, [updateOrderByFn]);

  const removeProductByUiIdAndPositionId = useCallback((orderId: string, uiId: string, positionId: string) => {
    deleteProductErrorsByUiId(orderId, uiId);

    updateOrderByFn((order) => ({
      ...order,
      products: order.products.filter((product) => (
        (uiId ? product.uiId !== uiId : true)
        && (positionId ? product.positionId !== positionId : true)
      )),
      didChangeMade: true,
    }));
    removeKeywordsByFieldId(positionId);
  }, [deleteProductErrorsByUiId, removeKeywordsByFieldId, updateOrderByFn]);

  useEffect(() => {
    const storedSortingColumn = localStorage.getItem('sortingColumn');
    if (storedSortingColumn !== null) {
      setSortingColumn(JSON.parse(storedSortingColumn));
    }

    const storedFilterEnabledProducts = localStorage.getItem('filterEnabledProducts');
    if (storedFilterEnabledProducts !== null) {
      setFilterEnabledProducts(JSON.parse(storedFilterEnabledProducts));
    }

    const storedSortExtractedProductsBy = localStorage.getItem('sortExtractedProductsBy');
    if (storedSortExtractedProductsBy !== null) {
      setSortExtractedProductsBy(JSON.parse(storedSortExtractedProductsBy));
    }
  }, []);

  useEffect(() => {
    localStorage.setItem('sortingColumn', JSON.stringify(sortingColumn));
  }, [sortingColumn]);

  useEffect(() => {
    localStorage.setItem('filterEnabledProducts', JSON.stringify(filterEnabledProducts));
  }, [filterEnabledProducts]);

  useEffect(() => {
    localStorage.setItem('sortExtractedProductsBy', JSON.stringify(sortExtractedProductsBy));
  }, [sortExtractedProductsBy]);

  return {
    updateProductByUiId,
    updateProductByUiIdAndPath,
    addNewProduct,
    removeProductByUiIdAndPositionId,

    sortingColumn,
    setSortingColumn,
    filterEnabledProducts,
    setFilterEnabledProducts,
    sortExtractedProductsBy,
    setSortExtractedProductsBy,
  };
};
