import { Order, ProductWithQuantity } from '../models/Order';

import { httpPostV1 } from 'utils/xhr';

import { groupOrderDrafts } from './groupOrderDrafts';

type CreateOrderDraftParams = {
  customerId: string;
  requestedDeliveryTime?: string;
  products?: ProductWithQuantity[];
  comment?: string;
  assigneeId?: string;
  promptId?: string;
  messageId?: string;
};

const _createOrderDraft = async (
  orderDraft: Order,
  messageId?: string,
): Promise<Order> => {
  if (!orderDraft.customer?.id) {
    return Promise.reject(new Error('Specify a customer for which to create the order draft'));
  }

  const params: CreateOrderDraftParams = {
    customerId: orderDraft.customer.id,
  };

  if (orderDraft.requestedDeliveryTime) params.requestedDeliveryTime = orderDraft.requestedDeliveryTime;
  if (orderDraft.products?.length > 0) params.products = orderDraft.products;
  if (orderDraft.draft?.comment) params.comment = orderDraft.draft?.comment;
  if (orderDraft.assigneeId) params.assigneeId = orderDraft.assigneeId;
  if (orderDraft.promptId) params.promptId = orderDraft.promptId;
  if (messageId) params.messageId = messageId;
  return httpPostV1('/orders/drafts', params)
    .then((response) => response.data)
    .catch((error) => Promise.reject(error));
};

export const createAndGroupOrderDrafts = async (
  orderDrafts: Order[],
  _ids: string[] = [],
  messageId?: string,
): Promise<Order[]> => {
  let responseBaseOrderDraft: Order;
  try {
    // create base product
    responseBaseOrderDraft = await _createOrderDraft(
      orderDrafts[0],
      messageId,
    );
  } catch (error) {
    return Promise.reject(error);
  }

  // if there was only one drafts then return the base draft
  if (orderDrafts.length === 1 && !_ids.length) {
    return Promise.resolve([responseBaseOrderDraft]);
  }

  if (!responseBaseOrderDraft) {
    return Promise.reject(new Error('Failed to create base order draft'));
  }

  const ids = [..._ids, responseBaseOrderDraft.id];
  const orders = [responseBaseOrderDraft];

  const promises = await Promise.all(
    orderDrafts.slice(1).map(async (orderDraft): Promise<any> => {
      try {
        const responseOrderDraft = await _createOrderDraft(
          orderDraft,
        );
        if (responseOrderDraft) {
          ids.push(responseOrderDraft.id);
          orders.push(responseOrderDraft);
          return responseOrderDraft;
        }
      } catch (error) {
        return Promise.reject(error);
      }
      return null;
    }),
  );

  if (promises.some((promise) => promise instanceof Error)) {
    return Promise.reject(new Error('Failed to create order drafts'));
  }

  // group orders
  return groupOrderDrafts({ orderIds: ids })
    .then((groupId) => orders.map((_order) => ({ ..._order, groupId })))
    .catch(() => Promise.reject(new Error('Failed to group order drafts')));
};
