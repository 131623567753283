import { InputProps } from '@mantine/core';

import { StandardFieldType } from 'features/instruction/models';
import { ProductWithQuantity } from 'features/order/models/Order';
import { Unit } from 'features/product/models/Product';

import { FieldType, UniversalField } from 'components/common/UniversalField';

import { IdOrSkuInput } from '../../form-elemts/IdOrSkuInput';
import { UnitSelect } from '../../form-elemts/UnitSelect';

interface Props {
  standardFieldType: StandardFieldType;
  productWithQuantity: ProductWithQuantity;
  label: string;
  type: FieldType;
  value: string;
  className: string;
  placeHolder: string;
  disabled: boolean;
  readOnly: boolean;
  itemStyle?: Record<string, React.CSSProperties>;

  onUnitChange: (unit: Unit) => void;
  onValueChange: (value: string) => void;
  setError: (error: string) => void;
}

const ProductStandardField = ({
  standardFieldType,
  productWithQuantity,
  label,
  type,
  value,
  className,
  placeHolder,
  disabled,
  readOnly,
  itemStyle,
  onUnitChange,
  onValueChange,
  setError,
}: Props) => {
  switch (standardFieldType) {
    case StandardFieldType.StandardFieldTypeProductUnit:
      return (
        <UnitSelect
          size="xs"
          productWithQuantity={productWithQuantity}
          label={label}
          onUnitChange={onUnitChange}
          setError={setError}
          inputStyles={itemStyle as InputProps['styles']}
        />
      );
    case StandardFieldType.StandardFieldTypeProductIdOrSku:
      return (
        <IdOrSkuInput
          label={label}
          productWithQuantity={productWithQuantity}
          onValueChange={onValueChange}
        />
      );
    case StandardFieldType.StandardFieldTypeProductQuantity:
      return (
        <UniversalField
          type={type}
          label={label}
          value={value}
          required
          className={className}
          placeHolder={placeHolder}
          disabled={disabled}
          readOnly={readOnly}
          validation="required"
          onValueChange={onValueChange}
          setError={setError}
          itemStyles={itemStyle}
        />
      );
    default:
      return (
        <UniversalField
          type={type}
          label={label}
          value={value}
          className={className}
          placeHolder={placeHolder}
          disabled={disabled}
          readOnly={readOnly}
          onValueChange={onValueChange}
          itemStyles={itemStyle}
        />
      );
  }
};

export default ProductStandardField;
