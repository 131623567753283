const ROUTES = {
  HOME: '/',

  SIGNIN: (username: string) => `/signin/${username}?`,
  INVITED_SIGNUP: (appLink: string) => `/invited-signup/${appLink}`,
  ACTIVATE_USER: '/activate-user',
  SEND_RESET_PASSWORD_LINK: '/send-reset-password-link',
  RESET_PASSWORD: '/password-reset',

  PRODUCTS: '/products',
  PRODUCT_BY_ID: (productId: string) => `/products/${productId}`,
  PRODUCTS_IMPORT: '/products/import',

  CUSTOMERS: '/customers',
  CUSTOMER_BY_ID: (customerId: string) => `/customers/${customerId}`,
  CUSTOMERS_IMPORT: '/customers/import',

  SUBJECTS: '/subjects',
  SUBJECT_BY_ID: (subjectId: string) => `/subjects/${subjectId}`,

  CHATS: '/chats',
  NEW_ADAM_CHAT: '/chats/adam/new',
  ADAM_CHAT: '/chat/adam',
  ADAM_CHAT_BY_ID: (chatId: string) => `/chat/adam/${chatId}`,
  CHAT_BY_ID: (chatId: string) => `/chats/${chatId}`,
  MESSAGE_BY_ID: (messageId: string) => `/messages/${messageId}`,
  INBOX: (params: Record<string, string>) => {
    const queryString = new URLSearchParams(params).toString();
    return queryString ? `/inbox?${queryString}` : '/inbox';
  },
  THREAD_BY_ID: (threadId: string) => `/threads/${threadId}`,

  ORDERS: '/orders',
  ORDER_BY_ID: (orderId: string) => `/orders/${orderId}`,

  PROCESS_ORDER_DRAFTS: '/orders/process-draft',
  PROCESS_ORDER_DRAFT_BY_ID: (id: string, isGroupId?: boolean) => {
    const base = `/orders/process-draft/${id}`;
    return isGroupId ? `${base}?is_group_id=true` : base;
  },
  PROCESS_NEW_ORDER_DRAFTS: (messageId?: string) => {
    const base = '/orders/process-new-draft';
    return messageId ? `${base}?message_id=${messageId}` : base;
  },

  TEAM_MEMBERS: '/team-members',
  TEAM_MEMBER_ADD: '/team-members/add',
  TEAM_MEMBER_BY_ID: (teamMemberId: string) => `/team-members/${teamMemberId}`,

  INSTRUCTIONS: '/instructions',
  CREATE_INSTRUCTION: '/instruction/create',
  EDIT_INSTRUCTION: (instructionId: string) => `/instruction/edit/${instructionId}`,

  AUDIT: '/audit',
  AUDIT_ERP_SYNC_LOGS: '/audit/erp-sync-logs',

  SETTINGS: '/settings',
};

export { ROUTES };
