import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import DOMPurify from 'dompurify';
import parse from 'html-react-parser';

import { Accordion } from '@mantine/core';

import { Message, MessageContextUtils } from 'features/message/models/Message';
import { ParsedEmail } from 'models/Mail';

import styles from './Accordion.module.css';

import { ThreadMessageAttachments } from '../ThreadMessageAttachments';

interface Props {
  message: Message;
  isAdamParticipating: boolean;
  isExpanded: boolean;
  parsedMessage: ParsedEmail | null;
}

const ThreadMessageContent = ({
  message, isAdamParticipating, isExpanded, parsedMessage,
}: Props) => {
  const { t } = useTranslation();

  const audioAttachments = MessageContextUtils.audioAttachments(message?.context);

  const purifiedHTMLOrText = useMemo(() => {
    if (parsedMessage) {
      const sanitizedHTML = DOMPurify.sanitize(parsedMessage.html || parsedMessage.text, {
        USE_PROFILES: { html: true },
      });
      return sanitizedHTML;
    }

    return null;
  }, [parsedMessage]);

  return (
    <>
      <div className={`mt-8 ${isAdamParticipating && isExpanded ? 'mb-16' : 'mb-4'} text-sm text-gray-900 whitespace-pre-wrap ${isExpanded ? '' : 'line-clamp-2'}`}>
        {purifiedHTMLOrText ? parse(purifiedHTMLOrText) : message.message}
      </div>

      <div className="bg-violet-hue-selection-button-3 p-3 rounded-md">
        {
        audioAttachments.length > 0 && (
          <>
            <div className="mb-2">
              <div className="text-sm text-gray-900 font-semibold">
                Audio Transcripts
              </div>
            </div>

            <Accordion classNames={styles}>
              {
                audioAttachments.map((attachment) => (
                  <Accordion.Item
                    key={attachment.name}
                    value={attachment.name}
                  >
                    <Accordion.Control>
                      <div className="text-sm text-gray-900">{attachment.name}</div>
                    </Accordion.Control>
                    <Accordion.Panel>
                      <div className="text-sm text-gray-900">{attachment.transcription}</div>
                    </Accordion.Panel>
                  </Accordion.Item>
                ))
              }
            </Accordion>
          </>
        )
      }
      </div>

      {isExpanded && message.context?.attachments && (
        <>
          <div className="mt-8">
            <div className="text-sm text-gray-900 font-semibold">
              {t('thread.attachments.title')}
            </div>
            <div className="h-px bg-gray-200 mt-2" />
          </div>
          <div className="flex flex-wrap">
            <ThreadMessageAttachments attachments={message.context.attachments} />
          </div>
        </>
      )}
    </>
  );
};

export default ThreadMessageContent;
