import { useEffect, useRef, useState } from 'react';

import { useInfiniteQuery } from '@tanstack/react-query';

import { Message } from 'features/message/models/Message';

import { useChatMessageWs, useLLMChatSender } from 'features/chat/hooks';

import LandingView from './LandingView';
import MainView from './MainView';
import { chatMessagesOptions } from 'features/chat/options';
import { useAiChatStore } from 'features/chat/stores';

interface Props {
  chatId: string;
}

const scrollToMessage = (messageId: string, timeout = 300) => {
  setTimeout(() => {
    const element = document.getElementById(messageId);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  }, timeout);
};

const RightSide = ({ chatId }: Props) => {
  const currentChat = useAiChatStore((state) => state.currentChat);
  const setCurrentChat = useAiChatStore((state) => state.setCurrentChat);
  const addChat = useAiChatStore((state) => state.addChat);

  const mainViewRef = useRef(null);

  const [tempMessage, setTempMessage] = useState<Message | null>(null);
  const [messages, setMessages] = useState([]);
  const [isAdamThinking, setIsAdamThinking] = useState(false);

  useChatMessageWs({
    chatId: currentChat?.id,
    onMessageReceived: (message) => {
      mainViewRef.current?.deactivateScrollToCurrentPosition();
      scrollToMessage(message.id);

      setMessages([...messages, message]);
    },
    stopAdamThinking: () => {
      setIsAdamThinking(false);
    },
  });

  const { sendMessage, isLoading } = useLLMChatSender({
    chat: currentChat,
    setTempMessage: (message) => {
      mainViewRef.current?.deactivateScrollToCurrentPosition();
      scrollToMessage(message.id);

      setTempMessage(message);
      setIsAdamThinking(true);
    },
    updateTempMessage: (updatedMessage) => {
      // If temp message is in messages, update it
      // Otherwise, add it to the messages ensuring that messages are ordered by date
      mainViewRef.current?.deactivateScrollToCurrentPosition();
      setTempMessage(null);
      setMessages((messages_) => [
        ...messages_,
        updatedMessage,
      ].sort((a, b) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()));
    },
    setChat: (chat) => {
      addChat(chat);
      setCurrentChat(chat);
    },
  });

  const {
    data, isFetching, hasNextPage, fetchNextPage,
  } = useInfiniteQuery({
    ...chatMessagesOptions(currentChat?.id),
    enabled: !!currentChat?.id,
  });

  useEffect(() => {
    if (data) {
      mainViewRef.current?.activateScrollToCurrentPosition();
      // We inverse the messages to display them in the correct order
      setMessages(data.pages.flatMap((page) => page.result).reverse());
    }
  }, [data]);

  useEffect(() => console.log('[messages]', messages), [messages]);

  if (chatId || currentChat) {
    return (
      <MainView
        ref={mainViewRef}
        messages={tempMessage ? [...messages, tempMessage] : messages}
        hasNextPage={hasNextPage}
        fetchNextPage={fetchNextPage}
        isAdamThinking={isAdamThinking}
        isFetchingMessages={isFetching}
        onSendMessage={sendMessage}
      />
    );
  }

  return <LandingView onSendMessage={sendMessage} isLoading={isLoading} />;
};

export default RightSide;
