import { LlmScore } from 'features/order/models/Order';

import { Tooltip } from 'components/ui';

import matchedByIdIcon from 'assets/icons/matched-by-id.svg';

interface ConfidenceLevelIndicatorProps {
  score: LlmScore;
  isMatchedByID?: boolean;
  className?: string;
}

const getConfidenceInfo = (score: LlmScore) => {
  const baseConfig = {
    dots: 4,
    containerColor: 'bg-indigo-50',
    activeColor: 'bg-indigo-600',
    inactiveColor: 'bg-indigo-100',
  };

  switch (score) {
    case LlmScore.ALMOST_SURE:
      return { ...baseConfig, activeDots: 4, label: 'High confidence match' };
    case LlmScore.SURE:
      return { ...baseConfig, activeDots: 3, label: 'Good confidence match' };
    case LlmScore.CONFIDENT:
      return { ...baseConfig, activeDots: 2, label: 'Moderate confidence match' };
    case LlmScore.NOT_CONFIDENT:
      return { ...baseConfig, activeDots: 1, label: 'Low confidence match' };
    case LlmScore.NOT_SURE:
      return { ...baseConfig, activeDots: 0, label: 'Very low confidence match' };
    default:
      return { ...baseConfig, activeDots: 0, label: 'Unknown confidence level' };
  }
};

const ConfidenceLevelIndicator = ({ score, isMatchedByID = false, className = '' }: ConfidenceLevelIndicatorProps) => {
  if (isMatchedByID) {
    return (
      <div className={`inline-flex ${className}`}>
        <Tooltip label="Matched by ID">
          <img src={matchedByIdIcon} alt="Matched by ID" className="w-7 h-7" />
        </Tooltip>
      </div>
    );
  }

  const {
    dots, activeDots, containerColor, activeColor, inactiveColor, label,
  } = getConfidenceInfo(score);

  return (
    <Tooltip label={label}>
      <div className={`inline-flex ${className}`}>
        <div className={`px-2 py-1 ${containerColor} rounded-full`}>
          <div className="flex items-center gap-1">
            {Array.from({ length: dots }).map((_, index) => (

              <div
                // eslint-disable-next-line react/no-array-index-key
                key={index}
                className={`w-2 h-2 rounded-full transition-colors duration-200 ${
                  index < activeDots ? activeColor : inactiveColor
                }`}
              />
            ))}
          </div>
        </div>
        <span className="sr-only">{label}</span>
      </div>
    </Tooltip>
  );
};

export default ConfidenceLevelIndicator;
