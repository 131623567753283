import { useTranslation } from 'react-i18next';

import { twMerge } from 'tailwind-merge';

import { Product } from 'features/product/models/Product';

import { PreviewOpenIcon } from 'components/icon/outline';
import { InfiniteScrollTable } from 'components/ui';

import { getUnits } from 'features/product/utils/product';
import { formatPrice } from 'utils/formatting';

interface Props {
  product: Product;
  idx: number;
  isRowSelected: boolean;
  onRowSelected: (product: Product) => void;
  onDetailsButtonClick: (productId: string) => void;
}

const ProductListTableRow = ({
  product,
  idx,
  isRowSelected,
  onRowSelected,
  onDetailsButtonClick,
}: Props) => {
  const { t } = useTranslation();

  return (
    <InfiniteScrollTable.Tr
      className={twMerge(
        'hover:bg-gray-50 group',
        isRowSelected && 'bg-gray-50',
      )}
    >
      <InfiniteScrollTable.Td className="relative w-13">
        {
          isRowSelected ? (
            <div className="absolute inset-y-0 left-0 w-0.5 bg-primary-500" />
          ) : (
            <p className="group-hover:hidden text-center">
              {idx + 1}
            </p>
          )
        }

        <input
          type="checkbox"
          className={twMerge(
            'absolute left-1/2 -translate-x-1/2 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-primary-500 group-hover:block hidden',
            isRowSelected && 'block',
          )}
          value={product.id}
          checked={isRowSelected}
          onChange={() => onRowSelected(product)}
          onClick={(e) => e.stopPropagation()}
        />
      </InfiniteScrollTable.Td>
      <InfiniteScrollTable.Td>
        <div className="relative">
          {product.idOrSku}

          <div className="hidden group-hover:block group-hover:absolute top-1/2 -translate-y-1/2 right-0 transition-all duration-200 ease-in-out">
            <button
              type="button"
              className="inline-flex items-center gap-1 px-1.5 py-0.5 rounded-[5px] relative border border-solid border-violet-hue-selection-button-1 hover:bg-violet-hue-selection-button-1 bg-white"
              onClick={() => onDetailsButtonClick(product.id)}
            >
              <PreviewOpenIcon className="size-[18px]" />
              <div className="relative w-fit mt-[-1.00px] font-font-screen-xs-medium font-[number:var(--font-screen-xs-medium-font-weight)] text-[length:var(--font-screen-xs-medium-font-size)] tracking-[var(--font-screen-xs-medium-letter-spacing)] leading-[var(--font-screen-xs-medium-line-height)] whitespace-nowrap [font-style:var(--font-screen-xs-medium-font-style)]">
                {t('product.list.header.view')}
              </div>
            </button>
          </div>
        </div>
      </InfiniteScrollTable.Td>
      <InfiniteScrollTable.Td>
        {product.name}
      </InfiniteScrollTable.Td>
      <InfiniteScrollTable.Td>
        {product.category}
      </InfiniteScrollTable.Td>
      <InfiniteScrollTable.Td className="!font-mono">
        {formatPrice(
          product?.price?.amount || 0,
          product?.price?.currency,
        )}
      </InfiniteScrollTable.Td>
      <InfiniteScrollTable.Td isLast>
        {getUnits(product)
          .map((u) => u.toLowerCase())
          .join(',')}
      </InfiniteScrollTable.Td>
    </InfiniteScrollTable.Tr>
  );
};

export default ProductListTableRow;
