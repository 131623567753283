import { memo, useMemo } from 'react';

import { ChatBubbleLeftIcon } from '@heroicons/react/24/outline';
import {
  ActionIcon, Popover, Text,
} from '@mantine/core';

import { Order, ProductWithQuantity } from '../../models/Order';

import { OverflowEllipsisParagraph } from 'components/ui/OverflowEllipsisParagraph';

interface Props {
  order: Order;
}

const Product = memo(({ productWithQuantity }: {
  productWithQuantity: ProductWithQuantity;
}) => {
  const details = useMemo(() => {
    const _details = [];

    if (productWithQuantity?.product?.idOrSku) _details.push(productWithQuantity?.product?.idOrSku);
    if (productWithQuantity?.product?.provenanceCode) _details.push(productWithQuantity?.product?.provenanceCode);

    return _details.join(',');
  }, [productWithQuantity]);

  return (
    <div className="flex justify-between gap-3 items-center py-2 border-b">
      <div className="flex-1 overflow-hidden flex justify-between gap-3 items-center">
        <div className="flex space-x-2 flex-1 overflow-hidden">
          <div className="space-y-1 gap-2 overflow-hidden">
            <OverflowEllipsisParagraph
              maxLines={1}
              isTooltipEnabled
            >
              <p className="text-semibold">
                {productWithQuantity?.product?.name}
              </p>
            </OverflowEllipsisParagraph>
            <p className="text-semibold text-gray-400 text-xs">{details}</p>
          </div>
          {
            productWithQuantity.comment && (
              <Popover width={200} position="bottom" withArrow shadow="md">
                <Popover.Target>
                  <ActionIcon p={0} variant="transparent" size="sm">
                    <ChatBubbleLeftIcon className="w-full h-full text-gray-600" />
                  </ActionIcon>
                </Popover.Target>
                <Popover.Dropdown>
                  <Text size="xs">{productWithQuantity.comment}</Text>
                </Popover.Dropdown>
              </Popover>
            )
          }
        </div>

        <div className="font-extralight text-end w-[15%]">
          {productWithQuantity?.quantity || '-'}
          {' '}
          {productWithQuantity?.unit?.symbol}
        </div>
      </div>
    </div>
  );
});

const Products = memo(({ order }: Props) => (
  <div className="flex flex-col gap-sm">
    {
      order?.products.map((p) => (
        <Product key={p.uiId} productWithQuantity={p} />
      ))
    }
  </div>
));

export default Products;
