import { twMerge } from 'tailwind-merge';

import { Subject } from 'features/user/models';

import { InfiniteScrollTable } from 'components/ui';
import { OverflowEllipsisParagraph } from 'components/ui/OverflowEllipsisParagraph';

interface Props {
  subject: Subject;
  idx: number;
  isRowSelected: boolean;
  onRowSelected: (subject: Subject) => void;
}

const SubjectsTableRow = ({
  subject, idx, isRowSelected, onRowSelected,
}: Props) => (
  <InfiniteScrollTable.Tr
    className={twMerge(
      'hover:bg-gray-50 group',
      isRowSelected && 'bg-gray-50',
    )}
  >
    <InfiniteScrollTable.Td className="relative w-13">
      {
          isRowSelected ? (
            <div className="absolute inset-y-0 left-0 w-0.5 bg-primary-500" />
          ) : (
            <p className="group-hover:hidden text-center">
              {idx + 1}
            </p>
          )
        }

      <input
        type="checkbox"
        className={twMerge(
          'absolute left-1/2 -translate-x-1/2 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-primary-500 group-hover:block hidden',
          isRowSelected && 'block',
        )}
        value={subject.id}
        checked={isRowSelected}
        onChange={() => onRowSelected(subject)}
        onClick={(e) => e.stopPropagation()}
      />
    </InfiniteScrollTable.Td>
    <InfiniteScrollTable.Td>
      {
          `${subject.firstName}${subject.lastName && ' '}${subject.lastName}` || '-'
      }
    </InfiniteScrollTable.Td>
    <InfiniteScrollTable.Td>
      <OverflowEllipsisParagraph
        maxLines={1}
        isTooltipEnabled
      >
        {subject.GetPhones().join(', ') || '-'}
      </OverflowEllipsisParagraph>
    </InfiniteScrollTable.Td>
    <InfiniteScrollTable.Td isLast>
      <OverflowEllipsisParagraph
        maxLines={1}
        isTooltipEnabled
      >
        {subject.GetEmails().join(', ') || '-'}
      </OverflowEllipsisParagraph>
    </InfiniteScrollTable.Td>
  </InfiniteScrollTable.Tr>
);

export default SubjectsTableRow;
