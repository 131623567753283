import { useTranslation } from 'react-i18next';

import { NumberInput, Popover } from '@mantine/core';

import { FieldSpec } from 'features/instruction/models';
import { ProductWithQuantity } from 'features/order/models/Order';

import { QuantityConversionPopupContent } from 'features/product/types/product';

import { FieldPathWrapper } from '../../ProcessView/components/utils';

import PopupContent from './PopupContent';

const WrappedNumberInput = FieldPathWrapper(NumberInput);

interface Props {
  productQuantityField: FieldSpec;
  product: ProductWithQuantity;
  onProductQuantityChange: (value: number) => void;

  quantityPopupContent: QuantityConversionPopupContent | null;
  onQuantityPopupCanceled: () => void;
  onQuantityPopupConfirmed: () => void;

  isInCollapsedContent?: boolean;
  label?: string;
}

const ProductQuantityInputWithPopup = ({
  productQuantityField, product,
  quantityPopupContent, onQuantityPopupCanceled, onQuantityPopupConfirmed, onProductQuantityChange,
  isInCollapsedContent, label,
}: Props) => {
  const { t } = useTranslation();

  return (
    <Popover
      opened={!!quantityPopupContent}
      position="top"
      width={350}
      shadow="md"
      withArrow
      styles={{
        arrow: {
          ...(!!quantityPopupContent && {
            border: '1px solid var(--mantine-color-warning-5)',
          }),
        },
      }}
    >
      <Popover.Target>
        {/* Here, span is required to make forwardRef work */}
        <span>
          <WrappedNumberInput
            fieldPath={productQuantityField?.path}
            fieldId={product.positionId}
            isProductField
            value={product.quantity}
            error={product.quantity <= 0 ? t('order.processOrderDrafts.rightSide.invalidQuantity') : ''}
            min={0}
            label={label}
            miw={isInCollapsedContent && 50}
            maw={isInCollapsedContent && 80}
            hideControls
            className="relative"
            styles={{
              error: {
                position: 'absolute',
              },
              input: {
                borderRadius: isInCollapsedContent ? 'var(--mantine-radius-md) 0 0 var(--mantine-radius-md)' : '',
                ...(!!quantityPopupContent && {
                  border: '1px solid var(--mantine-color-warning-5)',
                }),
              },
            }}
            onChange={onProductQuantityChange}
          />
        </span>
      </Popover.Target>
      <Popover.Dropdown
        onClick={(e) => e.stopPropagation()}
        style={{
          ...(!!quantityPopupContent && {
            border: '1px solid var(--mantine-color-warning-5)',
          }),
        }}
      >
        <PopupContent
          defaultQuantity={product.quantity}
          quantityPopupContent={quantityPopupContent}
          onQuantityPopupCanceled={onQuantityPopupCanceled}
          onQuantityPopupConfirmed={onQuantityPopupConfirmed}
          updateProductQuantity={onProductQuantityChange}
        />
      </Popover.Dropdown>
    </Popover>
  );
};

export default ProductQuantityInputWithPopup;
