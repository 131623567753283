/* eslint-disable no-duplicate-case */
import { useMemo } from 'react';

import { twMerge } from 'tailwind-merge';

import { OrderStatus } from '../../models/Order';

interface Props {
  status?: OrderStatus;
}

const OrderStatusBadge = ({ status }: Props) => {
  const color = useMemo(() => {
    switch (status) {
      case OrderStatus.New:
        return 'indigo';
      case OrderStatus.Confirmed:
        return 'success';
      case OrderStatus.Rejected:
        return 'failure';
      case OrderStatus.TimedOut:
        return 'warning';
      default:
        return 'grey';
    }
  }, [status]);

  const className = useMemo(() => {
    switch (status) {
      case OrderStatus.New:
        return 'text-blue-700 bg-blue-100';
      case OrderStatus.Confirmed:
        return 'text-success-700 bg-success-100';
      case OrderStatus.Rejected:
        return 'text-danger-700 bg-danger-100';
      default:
        return 'text-neutral-700 bg-neutral-100';
    }
  }, [status]);

  return (
    // <Badge color={color}>
    //   {status || '-'}
    // </Badge>
    <div
      className={twMerge(
        'rounded-sm px-sm py-xs text-xs font-semibold',
        className,
      )}
    >
      {status || '-' || color}
    </div>
  );
};

export { OrderStatusBadge };
