import { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { Business } from '../../../../models/Business';
import { Order } from '../../models/Order';

import { TextWithLabel } from 'components/ui/TextWithLabel';

interface Props {
  order: Order;
}

const MemoizedContent = memo(({ customer }: {
  customer: Business
}) => {
  const { t } = useTranslation();

  return (
    <div className="grid grid-cols-2 gap-sm space-y-1">
      <TextWithLabel
        label={t('order.details.card.customer.name')}
        value={customer?.name}
        className="col-span-2"
      />

      {customer?.externalId && (
      <TextWithLabel
        label={t('order.details.card.customer.customerID')}
        value={customer?.externalId}
        className="col-span-1"
      />
      )}

      {customer?.shortName && (
      <TextWithLabel
        label={t('order.details.card.customer.shortName')}
        value={customer?.shortName}
        className="col-span-1"
      />
      )}

      {customer?.address && (
      <TextWithLabel
        label={t('order.details.card.customer.address')}
        value={customer?.address}
        className="col-span-2"
      />
      )}

      {customer?.emails && (
      <TextWithLabel
        label={t('order.details.card.customer.emails')}
        value={(customer?.emails || []).join(', ')}
        className="col-span-2"
      />
      )}

      {customer?.phones && (
      <TextWithLabel
        label={t('order.details.card.customer.phones')}
        value={(customer?.phones || []).map((phone) => phone.number).join(', ')}
        className="col-span-2"
      />
      )}
    </div>
  );
});

const Customer = ({ order }: Props) => (
  <MemoizedContent customer={order?.customer} />
);

export default Customer;
