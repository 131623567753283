import {
  useCallback, useEffect, useMemo, useRef,
} from 'react';

import { Order } from 'features/order/models/Order';

import { isZeroId } from 'utils/objectId';

interface Props {
  order: Order;
}

const stopWatchInterval = 50;

const useStopWatch = ({ order }: Props) => {
  const localStorageKey = useMemo(() => (order?.id ? `${order.id}/timeToFinalize` : ''), [order?.id]);

  // avoid re-rendering
  const elapsedTimeRef = useRef<number>(0);
  const intervalRef = useRef<NodeJS.Timeout | null>(null);

  const startStopwatch = useCallback((savedTime?: string) => {
    if (savedTime && savedTime !== '0') {
      elapsedTimeRef.current = Number(savedTime);
    }
    intervalRef.current = setInterval(() => {
      elapsedTimeRef.current += stopWatchInterval;
    }, stopWatchInterval);
  }, []);

  const stopStopwatch = useCallback(() => {
    try {
      if (localStorageKey) {
        localStorage.setItem(localStorageKey, String(elapsedTimeRef.current));
      }
    } catch (error) {
      console.error('Error saving to localStorage', error);
    }
  }, [localStorageKey]);

  useEffect(() => {
    const savedTime = localStorage.getItem(localStorageKey);
    // already assigned, so we should start the stopwatch
    if (order?.assigneeId && !isZeroId(order?.assigneeId)) {
      startStopwatch(savedTime);
    }

    return () => {
      stopStopwatch();
      elapsedTimeRef.current = 0;
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
        intervalRef.current = null;
      }
    };
  }, [order?.id, order?.assigneeId, localStorageKey, startStopwatch, stopStopwatch]);

  return {
    startStopwatch,
    stopStopwatch,
  };
};

export default useStopWatch;
