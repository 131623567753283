import { OrderPrices } from '../models/Order';

import { httpGetV1 } from 'utils/xhr';

export const fetchOrderPrices = (orderId: string): Promise<OrderPrices> => httpGetV1(
  `/orders/${orderId}/prices`,
  {
    classType: OrderPrices,
  },
)
  .then((response) => Promise.resolve(response.data as OrderPrices));
