import { useCallback, useEffect, useRef } from 'react';

import { plainToClass } from 'class-transformer';

import { Message } from 'features/message/models/Message';

import { MessageDirection } from 'features/message/types';

import { getMessageDirectionByUserId } from 'features/message/utils';
import { genericErrorFeedback } from 'utils/errors';
import { openAuthWebSocket } from 'utils/ws';

interface Props {
  chatId: string;

  onMessageReceived: (message: Message) => void;
  stopAdamThinking?: () => void;
}

export const useChatMessageWs = ({ chatId, onMessageReceived, stopAdamThinking }: Props) => {
  const messagesWs = useRef<WebSocket | null>();

  const onWsMessageReceived = useCallback((event: MessageEvent) => {
    const message = plainToClass(Message, JSON.parse(event.data)) as Message;

    if (message.chatId !== chatId) return;

    const direction = getMessageDirectionByUserId(message);

    if (direction === MessageDirection.Received) {
      onMessageReceived(message);

      stopAdamThinking?.();
    }
  }, [chatId, onMessageReceived, stopAdamThinking]);

  const onWsError = useCallback((error: unknown) => {
    genericErrorFeedback('Error loading live messages')(error);
  }, []);

  useEffect(() => {
    messagesWs.current = openAuthWebSocket('/v1/chat/ws');
    messagesWs.current.addEventListener('message', onWsMessageReceived);
    messagesWs.current.addEventListener('error', onWsError);

    return () => {
      messagesWs.current?.removeEventListener('message', onWsMessageReceived);
      messagesWs.current?.removeEventListener('error', onWsError);
      messagesWs.current?.close();
      messagesWs.current = null;
    };
  }, [onWsMessageReceived, onWsError, chatId]);
};
