import {
  useCallback,
  useRef,
} from 'react';
import { useTranslation } from 'react-i18next';

import { Order } from 'features/order/models/Order';

import {
  CalendarIcon,
  DollarSignIcon, MenuParagraphIcon, ShopIcon, ShoppingBagIcon,
  TruckIcon,
} from 'components/icon/outline';
import ArrowExportIcon from 'components/icon/outline/ArrowExportIcon';
import { InfiniteScrollTable, InfiniteScrollTableContextProps } from 'components/ui';

import OrderListTableRow from './OrderListTableRow';

export interface Props extends InfiniteScrollTableContextProps {
  orders: Order[];
  onDetailsButtonClick: (orderId: string, isDraft: boolean) => void;
}

interface SelectedOrderIds {
  selectedOrderIds: string[];
  setSelectedOrderIds: (orderIds: string[]) => void;
}

const OrderListTable = ({
  orders,
  selectedOrderIds,
  setSelectedOrderIds,
  onDetailsButtonClick,
  ...props
}: Props & SelectedOrderIds) => {
  const { t } = useTranslation();

  const checkbox = useRef<HTMLInputElement>();

  const toggleAll = useCallback(() => {
    if (selectedOrderIds.length === orders.length) {
      setSelectedOrderIds([]);
    } else {
      setSelectedOrderIds(orders.map((order) => order.id));
    }
  }, [orders, selectedOrderIds, setSelectedOrderIds]);

  const onSelectionChange = useCallback((order: Order) => {
    if (selectedOrderIds.includes(order.id)) {
      setSelectedOrderIds(selectedOrderIds.filter((o) => o !== order.id));
    } else {
      setSelectedOrderIds([...selectedOrderIds, order.id]);
    }
  }, [selectedOrderIds, setSelectedOrderIds]);

  return (
    <InfiniteScrollTable
      {...props}
    >
      <InfiniteScrollTable.Thead>
        <tr>
          <InfiniteScrollTable.Th>
            <input
              type="checkbox"
              className="absolute left-1/2 -translate-x-1/2 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-primary-500"
              ref={checkbox}
              checked={selectedOrderIds.length === orders.length}
              onChange={toggleAll}
            />
          </InfiniteScrollTable.Th>
          <InfiniteScrollTable.Th
            text={t('order.list.header.status')}
            Icon={MenuParagraphIcon}
          />
          <InfiniteScrollTable.Th
            text={t('order.list.header.customer')}
            Icon={ShopIcon}
          />
          <InfiniteScrollTable.Th
            text={t('order.list.header.products')}
            Icon={ShoppingBagIcon}
          />
          <InfiniteScrollTable.Th
            text={t('order.list.header.totalPrice')}
            Icon={DollarSignIcon}
          />
          <InfiniteScrollTable.Th
            text={t('order.list.header.requestedDelivery')}
            Icon={TruckIcon}
          />
          <InfiniteScrollTable.Th
            text={t('order.list.header.createdAt')}
            Icon={CalendarIcon}
          />
          <InfiniteScrollTable.Th
            text={t('order.list.header.didExport')}
            Icon={ArrowExportIcon}
          />
          <InfiniteScrollTable.Th
            text=""
            isLast
          />
        </tr>
      </InfiniteScrollTable.Thead>
      <InfiniteScrollTable.Tbody>
        {orders.map((order, idx) => (
          <OrderListTableRow
            key={order.id}
            idx={idx}
            order={order}
            isRowSelected={selectedOrderIds.includes(order.id)}
            onRowSelected={onSelectionChange}
            onDetailsButtonClick={onDetailsButtonClick}
          />
        ))}
      </InfiniteScrollTable.Tbody>
    </InfiniteScrollTable>
  );
};

export default OrderListTable;
