import { useTranslation } from 'react-i18next';

import { InboxList, ThreadListTable } from '../components';
import { Page } from 'components/layout/Page/Page';

const InboxPage = () => {
  const { t } = useTranslation();

  return (
    <Page
      pageTitle={t('inbox.title')}
    >
      <div className="flex-1 overflow-hidden flex gap-4">
        <div className="h-full w-[15%] 4xl:w-[10%] bg-white py-4 px-2 rounded-md 4xl:py-5 4xl:px-4 4xl:rounded-lg border border-light-grey shadow-blue-light-tint-drop-shadow">
          <InboxList />
        </div>
        <div className="flex-1 bg-white flex flex-col overflow-hidden gap-3 border border-light-grey shadow-blue-light-tint-drop-shadow rounded-md">
          {/* <MessageListTable /> */}
          <ThreadListTable />
        </div>
      </div>
    </Page>
  );
};

export default InboxPage;
