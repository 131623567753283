import { Expose, Type } from 'class-transformer';

import { Business, Organization } from 'models/Business';

import { DirectedAcyclicGraph } from 'typescript-graph';

import { ContactMedium } from './contactUser';

enum AssignableUserRole {
  AIAssistant = 'AI_ASSISTANT',
  BusinessSuperAdmin = 'BUSINESS_SUPER_ADMIN',
  BusinessAdmin = 'BUSINESS_ADMIN',
  BusinessUser = 'BUSINESS_USER',
}

enum NonAssignableUserRole {
  SystemAdmin = 'SYSTEM_ADMIN',
  SystemUser = 'SYSTEM_USER',
  BasicUser = 'BASIC_USER',
  BasicBusinessUser = 'BASIC_BUSINESS_USER',
  AuditLogsViewer = 'AUDIT_LOGS_VIEWER',
  BusinessSettingsEditor = 'BUSINESS_SETTINGS_EDITOR',
  BusinessSettingsViewer = 'BUSINESS_SETTINGS_VIEWER',
  BasicBusinessSettingsEditor = 'BASIC_BUSINESS_SETTINGS_EDITOR',
  BasicBusinessSettingsViewer = 'BASIC_BUSINESS_SETTINGS_VIEWER',
  OrganizationImporter = 'ORGANIZATION_IMPORTER',
  OrganizationCreator = 'ORGANIZATION_CREATOR',
  OrganizationEditor = 'ORGANIZATION_EDITOR',
  OrganizationViewer = 'ORGANIZATION_VIEWER',
  OrderImporter = 'ORDER_IMPORTER',
  OrderExporter = 'ORDER_EXPORTER',
  OrderCreator = 'ORDER_CREATOR',
  OrderEditor = 'ORDER_EDITOR',
  OrderViewer = 'ORDER_VIEWER',
  OrderProcessor = 'ORDER_PROCESSOR',
  ProductImporter = 'PRODUCT_IMPORTER',
  ProductExporter = 'PRODUCT_EXPORTER',
  ProductCreator = 'PRODUCT_CREATOR',
  ProductEditor = 'PRODUCT_EDITOR',
  ProductViewer = 'PRODUCT_VIEWER',
  UserCreator = 'USER_CREATOR',
  UserEditor = 'USER_EDITOR',
  UserViewer = 'USER_VIEWER',
  RoleAssigner = 'ROLE_ASSIGNER',
  RuleCreator = 'RULE_CREATOR',
  RuleEditor = 'RULE_EDITOR',
  RuleViewer = 'RULE_VIEWER',
  InboxCreator = 'INBOX_CREATOR',
  InboxEditor = 'INBOX_EDITOR',
  InboxViewer = 'INBOX_VIEWER',
  AnalyticsViewer = 'ANALYTICS_VIEWER',
  BusinessSalesViewer = 'BUSINESS_SALES_VIEWER',
  CustomerChurnRateViewer = 'CUSTOMER_CHURN_RATE_VIEWER',
  CustomerSalesViewer = 'CUSTOMER_SALES_VIEWER',
  CustomerSegmentsViewer = 'CUSTOMER_SEGMENTS_VIEWER',
  ProductSalesViewer = 'PRODUCT_SALES_VIEWER',
  OrderMetricsViewer = 'ORDER_METRICS_VIEWER',
  ChatViewer = 'CHAT_VIEWER',
  ErpModelImporter = 'ERP_MODEL_IMPORTER',
  ErpSyncLogsViewer = 'ERP_SYNC_LOGS_VIEWER',
  ErpHeaderMatcherCreator = 'ERP_HEADER_MATCHER_CREATOR',
  ErpHeaderMatcherEditor = 'ERP_HEADER_MATCHER_EDITOR',
  ErpHeaderMatcherViewer = 'ERP_HEADER_MATCHER_VIEWER',
  InstructionCreator = 'INSTRUCTION_CREATOR',
  InstructionEditor = 'INSTRUCTION_EDITOR',
  InstructionViewer = 'INSTRUCTION_VIEWER',
  SubjectCreator = 'SUBJECT_CREATOR',
  SubjectEditor = 'SUBJECT_EDITOR',
  SubjectViewer = 'SUBJECT_VIEWER',
}

type UserRole = AssignableUserRole | NonAssignableUserRole;

type UserRolesDag = DirectedAcyclicGraph<UserRole>;

function getAssignableRoles() {
  return new Set<UserRole>(Object.values(AssignableUserRole));
}

function getNonAssignableRoles() {
  return new Set<UserRole>(Object.values(NonAssignableUserRole));
}

function newUserRolesDagFromCsv(rolesCsv: string): UserRolesDag {
  const roleEdges = rolesCsv
    .split('\n')
    .filter((roleEdge: string) => roleEdge.trim() !== '')
    .map((roleEdge: string) => roleEdge.split(',').slice(1));
  const roles = new Set(
    roleEdges.reduce((acc: UserRole[], roleEdge: string[]) => {
      const [parent, child] = roleEdge;
      acc.push(parent as UserRole);
      acc.push(child as UserRole);
      return acc;
    }, []),
  );

  // Check that the retrieved roles are all valid
  const allRoles = new Set([...Array.from(getAssignableRoles()), ...Array.from(getNonAssignableRoles())]);
  roles.forEach((role) => {
    if (!allRoles.has(role as UserRole)) {
      throw new Error(`Role ${role} is not valid. Please check the roles file and/or the role enums.`);
    }
  });

  const dag = new DirectedAcyclicGraph<UserRole>((n: UserRole) => n);
  roles.forEach((role) => {
    dag.insert(role as UserRole);
  });
  roleEdges.forEach((roleEdge) => {
    const [parent, child] = roleEdge;
    dag.addEdge(parent, child);
  });

  return dag;
}

class User {
  @Expose({ name: 'external_id' })
    externalId?: string;

  @Expose({ name: 'subject_data' })
    subjectData: SubjectData;

  @Expose({ name: 'id' })
    id: string;

  @Expose({ name: 'username' })
    username: string;

  @Expose({ name: 'first_name' })
    firstName: string;

  @Expose({ name: 'last_name' })
    lastName: string;

  @Expose({ name: 'email' })
    email: string;

  @Expose({ name: 'emails' })
    emails: string[];

  @Expose({ name: 'phone' })
    phone: string;

  @Expose({ name: 'phones' })
    phones: string[];

  @Expose({ name: 'roles' })
  @Type(() => Array<AssignableUserRole>)
    roles: AssignableUserRole[];

  @Expose({ name: 'external' })
    external: boolean;

  @Expose({ name: 'business' })
  @Type(() => Business)
    business: Business;

  @Expose({ name: 'contact_medium' })
    contactMedium: ContactMedium;

  @Expose({ name: 'activated_at' })
    activatedAt: string;

  @Expose({ name: 'updated_at' })
    updatedAt: string;

  GetEmail(): string {
    if (this.emails.length > 0) {
      return this.emails[0];
    }
    if (this.email !== '') {
      return this.email;
    }

    return '';
  }

  GetPhone(): string {
    if (this.phones.length > 0) {
      return this.phones[0];
    }
    if (this.phone !== '') {
      return this.phone;
    }
    return '';
  }

  GetEmails(): string[] {
    if (this.emails.length > 0) {
      return this.emails;
    }
    if (this.email !== '') {
      return [this.email];
    }
    return [];
  }

  GetPhones(): string[] {
    if (this.phones.length > 0) {
      return this.phones;
    }
    if (this.phone !== '') {
      return [this.phone];
    }
    return [];
  }
}

class SubjectData {
  organizationId: string;

  organization: Organization;
}

class Subject extends User {}

export {
  AssignableUserRole, newUserRolesDagFromCsv,
  NonAssignableUserRole, Subject, User, UserRole, UserRolesDag,
};
