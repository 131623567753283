import { ProductWithQuantity } from '../models/Order';

import { httpPatchV1, httpPutV1 } from 'utils/xhr';

type UpdateOrderDraftParams = {
  customerId?: string;

  products?: ProductWithQuantity[];

  requestedDeliveryTime?: string;

  comment?: string;
};

type AssignTeamMeberProps = {
  orderId: string;
  userId: string;
  assignedComment?: string;
};

export const assignOrderDraft = async ({
  orderId,
  userId,
  assignedComment = '',
}: AssignTeamMeberProps): Promise<any> => {
  if (!orderId) {
    return Promise.reject(new Error('orderId is required'));
  }
  if (!userId) {
    return Promise.reject(new Error('userId is required'));
  }

  if (assignedComment.trim() !== '') {
    try {
      const params: UpdateOrderDraftParams = {
        comment: assignedComment,
      };
      await httpPatchV1(`/orders/drafts/${orderId}`, params);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  return httpPutV1(`/orders/${orderId}/assignee`, {
    assignee_id: userId,
  });
};
