import {
  memo, useCallback, useEffect, useMemo, useRef, useState,
} from 'react';
import { NavLink } from 'react-router-dom';

import { twMerge } from 'tailwind-merge';
import { v4 as uuidv4 } from 'uuid';

import { PaperAirplaneIcon } from '@heroicons/react/24/outline';
import {
  LoadingOverlay, Popover, ScrollArea, Textarea,
} from '@mantine/core';

import { ROUTES } from 'config/routes';

import { globalUser } from '../../../../state/globalUser';

import { Chat } from '../../../../features/chat/models';
import { Message } from '../../../../features/message/models/Message';

import { MessageDirection } from '../../../../features/message/types/message';

import { ChatMessagesProvider, useChatMessagesContext } from '../../../../contexts/useChatMessagesContext';

import { Card } from 'components/common/Card';

import adam from '../../../../assets/adam-face.png';

import { decodeEntities, getMessageDirection } from '../../../../features/message/utils/message';

import { AdamMessageWrapper } from '../../../wrapper/AdamMessageWrapper';
import AdamText from './AdamText';

const ChatWithAdamMessage = memo(({
  message,
}: {
  message: Message;
}) => {
  const { setMessages } = useChatMessagesContext();
  const direction = useMemo(() => getMessageDirection(message, globalUser.business), [message]);

  const callback = useCallback(() => {
    console.log('callback', message);
    setMessages((messages) => messages?.map((msg) => {
      if (msg.id === message.id) {
        return { ...msg, isAdded: false };
      }
      return msg;
    }));
  }, [message, setMessages]);

  if (direction === MessageDirection.Received) {
    return (
      <span id={message.id}>
        <AdamText
          text={message.message}
          useTypeWriter={!!message.isAdded}
          callback={callback}
        />
      </span>
    );
  }

  return (
    <div id={message.id} className="flex justify-end">
      <div className="py-2 px-2 bg-primary-500 text-white w-fit rounded-md max-w-[80%]">
        {decodeEntities(message.message)}
      </div>
    </div>
  );
});

const ChatWithAdamMessages = memo(({
  messages,
}: {
  messages: Message[],
}) => {
  const { isAdamThinking } = useChatMessagesContext();
  return (
    <span className="flex-1 space-y-6">
      {messages.map((message) => (
        <ChatWithAdamMessage key={message.id} message={message} />
      ))}
      {isAdamThinking && (
      <AdamMessageWrapper variant="small">
        <div className="relative h-[1ch] w-20">
          <LoadingOverlay
            visible
            loaderProps={{ type: 'dots' }}
            overlayProps={{ blur: 2 }}
          />
        </div>
      </AdamMessageWrapper>
      )}
    </span>
  );
});

const ChatWithAdamContent = ({
  messages,
  setChat,
}: {
  messages: Message[];
  setChat: React.Dispatch<React.SetStateAction<Chat>>;
}) => {
  const {
    userInput,
    setUserInput,
    isAdamThinking,
    onMessageSubmit,
    isSendingLLMMessage,
  } = useChatMessagesContext();

  const mainWrapperRef = useRef<HTMLDivElement>(null);
  const textareaWrapperRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const chatId = messages.find((message) => message.chatId)?.chatId;
    if (chatId) {
      setChat((chat) => ({
        ...chat,
        id: chatId,
      }));
    }
  }, [messages, setChat]);

  return (
    <div className="max-h-[80vh] flex flex-col gap-5">
      <ScrollArea.Autosize
        scrollbars="y"
        className="flex-1 space-y-6"
        viewportRef={mainWrapperRef}
      >
        <ChatWithAdamMessages messages={messages} />
      </ScrollArea.Autosize>
      {/* Textarea */}
      <div
        ref={textareaWrapperRef}
        className="flex max-h-[4rem] items-end"
      >
        <Textarea
          placeholder=""
          disabled={isAdamThinking || isSendingLLMMessage}
          className="w-full border-0 focus:ring-0 focus-visible:ring-0"
          radius="lg"
          autosize
          maxRows={3}
          value={userInput}
          onChange={(e) => {
            setUserInput(e.currentTarget.value);

            if (textareaWrapperRef.current) {
              textareaWrapperRef.current.style.height = `${e.target.scrollHeight}px`;
            }
            if (mainWrapperRef.current) {
              mainWrapperRef.current.scrollTop = mainWrapperRef.current.scrollHeight;
            }
          }}
          onKeyDown={(e) => {
            if (e.key === 'Enter' && !e.shiftKey) {
              e.preventDefault();
              if (userInput.length > 0) {
                onMessageSubmit('adam_assistant');
              }
            }
          }}
          rightSection={(
            <div className="flex h-full flex-col justify-end pb-[0.5rem]">
              <PaperAirplaneIcon
                className={`h-5 w-5 ${isAdamThinking || isSendingLLMMessage ? 'text-gray-600' : 'text-gray-400'}`}
              />
            </div>
        )}
        />
      </div>
    </div>
  );
};

const ChatWithAdam = memo(() => {
  const [chat, setChat] = useState<Chat>({
    id: '',
    business: globalUser.business,
    lastMessage: null,
    lastMessageCreatedAt: null,
    lastMessageRefAction: null,
    lastMessageRefType: null,
    lastMessageType: null,
    lastMessageSource: null,
  });
  const [messages, setMessages] = useState<Message[]>([
    // landing message
    {
      id: uuidv4(),
      message: 'Hi there! I\'m <strong>Adam</strong>, your AI assistant. I\'m here to help you with whatever you need.',
      isAdamThinking: true,
    } as Message,
  ]);

  const onOpen = useCallback(() => {
    const lastMessage = messages[messages.length - 1];
    setTimeout(() => {
      const lastMessageDiv = document.getElementById(lastMessage.id);
      if (lastMessageDiv) {
        lastMessageDiv.scrollIntoView({ behavior: 'smooth', block: 'end' });
      }
    }, 100);
  }, [messages]);

  return (
    <NavLink to={ROUTES.ADAM_CHAT}>
      <div
        className={twMerge(
          'inline-flex items-center justify-center gap-2 pl-0 py-0 relative flex-[0_0_auto] rounded-full desktop:bg-[rgba(255,255,255,1.0)] [background:linear-gradient(90deg,rgba(130,73,242,1)_0%,rgba(190,153,240,1)_100%)] shadow-blue-light-tint-drop-shadow cursor-pointer',
          'pr-3 desktop:pr-4',
        )}
      >
        <img
          src={adam}
          alt="adam"
          className="relative size-[30px] desktop:size-9 object-cover rounded-full"
        />
        <div className="inline-flex items-center relative flex-[0_0_auto]">
          <div className={twMerge(
            'relative w-fit mt-[-1.00px] whitespace-nowrap text-white',
            'font-font-screen-sm-regular font-[number:var(--font-screen-sm-regular-font-weight)] text-[length:var(--font-screen-sm-regular-font-size)] tracking-[var(--font-screen-sm-regular-letter-spacing)] leading-[var(--font-screen-sm-regular-line-height)] [font-style:var(--font-screen-sm-regular-font-style)]',
            'desktop:font-font-screen-nav-bar-medium desktop:font-[number:var(--font-screen-nav-bar-medium-font-weight)] desktop:text-[length:var(--font-screen-nav-bar-medium-font-size)] desktop:tracking-[var(--font-screen-nav-bar-medium-letter-spacing)] desktop:leading-[var(--font-screen-nav-bar-medium-line-height)] desktop:[font-style:var(--font-screen-nav-bar-medium-font-style)]',
          )}
          >
            Chat with Adam
          </div>
        </div>
      </div>
    </NavLink>
  );

  return (
    <Popover
      position="bottom"
      clickOutsideEvents={['mouseup', 'touchend']}
      transitionProps={{ transition: 'pop', duration: 200 }}
      shadow="md"
      withArrow
      onOpen={onOpen}
    >
      <Popover.Target>
        <div
          className={twMerge(
            'inline-flex items-center justify-center gap-2 pl-0 py-0 relative flex-[0_0_auto] rounded-full desktop:bg-[rgba(255,255,255,1.0)] [background:linear-gradient(90deg,rgba(130,73,242,1)_0%,rgba(190,153,240,1)_100%)] shadow-blue-light-tint-drop-shadow cursor-pointer',
            'pr-3 desktop:pr-4',
          )}
        >
          <img
            src={adam}
            alt="adam"
            className="relative size-[30px] desktop:size-9 object-cover rounded-full"
          />
          <div className="inline-flex items-center relative flex-[0_0_auto]">
            <div className={twMerge(
              'relative w-fit mt-[-1.00px] whitespace-nowrap text-white',
              'font-font-screen-sm-regular font-[number:var(--font-screen-sm-regular-font-weight)] text-[length:var(--font-screen-sm-regular-font-size)] tracking-[var(--font-screen-sm-regular-letter-spacing)] leading-[var(--font-screen-sm-regular-line-height)] [font-style:var(--font-screen-sm-regular-font-style)]',
              'desktop:font-font-screen-nav-bar-medium desktop:font-[number:var(--font-screen-nav-bar-medium-font-weight)] desktop:text-[length:var(--font-screen-nav-bar-medium-font-size)] desktop:tracking-[var(--font-screen-nav-bar-medium-letter-spacing)] desktop:leading-[var(--font-screen-nav-bar-medium-line-height)] desktop:[font-style:var(--font-screen-nav-bar-medium-font-style)]',
            )}
            >
              Chat with Adam
            </div>
          </div>
        </div>
      </Popover.Target>
      <Popover.Dropdown>
        <Card.Body className="relative px-0 py-0 w-[25vw] 2xl:w-[20vw]">
          <ChatMessagesProvider setMessages={setMessages} chat={chat} setChat={setChat} isAdamTemporaryChat>
            <ChatWithAdamContent messages={messages} setChat={setChat} />
          </ChatMessagesProvider>
        </Card.Body>
      </Popover.Dropdown>
    </Popover>
  );
});

export default ChatWithAdam;
