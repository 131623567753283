import { ORDER_DRAFT_PWQ_NAME_PATH } from 'features/instruction/constants';

import { Schema } from 'features/instruction/models';
import { Order } from 'features/order/models/Order';

import { ErrorsAndWarningsState } from './types';

import {
  getCustomerFields as getCustomerFieldsFromInstruction,
  getGroupKeyByPath as getGroupKeyByPathFromInstruction,
  getProductFields as getProductFieldsFromInstruction,
  getStandardFields as getStandardFieldsFromInstruction,
  groupFields as groupFieldsFromInstruction,
} from 'features/instruction/utils';

import { DefaultGroupErrorsAndWarningsState } from './errorsAndWarningsSlice';

export const getErrorsOrWarnings = (errorsOrWarnings: ErrorsAndWarningsState, orderId: string) => (
  errorsOrWarnings[orderId] || DefaultGroupErrorsAndWarningsState);

export const getFilteredErrors = (errors: ErrorsAndWarningsState): string[] => (
  Object.values(errors)
    .map((groupErrsRec) => Object.values(groupErrsRec))
    .flat()
    .map((errsRec) => Object.values(errsRec))
    .flat()
    .filter((e) => e !== '')
);

export const getOrderFields = (groupOrders: Record<string, Order>, selectedOrderId: string) => {
  const order = groupOrders[selectedOrderId];
  return order?.typeSpecs?.[0]?.fields;
};

export const getSchema = (groupOrders: Record<string, Order>, selectedOrderId: string, schemas: Record<string, Schema>) => {
  const typeRef = groupOrders[selectedOrderId]?.typeSpecs?.[0]?.typeRef;

  return schemas[typeRef];
};

export const getProductFields = (groupOrders: Record<string, Order>, selectedOrderId: string) => (
  getProductFieldsFromInstruction(getOrderFields(groupOrders, selectedOrderId)));

export const getCustomerFields = (groupOrders: Record<string, Order>, selectedOrderId: string) => (
  getCustomerFieldsFromInstruction(getOrderFields(groupOrders, selectedOrderId)));

export const getStandardFields = (groupOrders: Record<string, Order>, selectedOrderId: string) => (
  getStandardFieldsFromInstruction(getOrderFields(groupOrders, selectedOrderId)));

export const getGroupedFields = (
  groupOrders: Record<string, Order>,
  selectedOrderId: string,
  schemas: Record<string, Schema>,
) => {
  const orderFields = getOrderFields(groupOrders, selectedOrderId);
  const typeRef = groupOrders[selectedOrderId]?.typeSpecs[0]?.typeRef;

  return groupFieldsFromInstruction(orderFields, schemas[typeRef]);
};

export const getProductGroupKey = (
  groupOrders: Record<string, Order>,
  selectedOrderId: string,
  schemas: Record<string, Schema>,
) => (
  getGroupKeyByPathFromInstruction(ORDER_DRAFT_PWQ_NAME_PATH, getGroupedFields(groupOrders, selectedOrderId, schemas))
);
