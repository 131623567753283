import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';

import Dayjs from 'dayjs';

import { Button } from '@mantine/core';

import { ROUTES } from 'config/routes';

import { OrderStatus } from 'features/order/models/Order';

import { ArrowRedoIcon, PlusIcon } from 'components/icon/outline';

import { doesExist } from 'utils/comparison';

import { AssigneeSelect } from './AssigneeSelect';
import { DatePickers } from './DatePickers';
import { ExportedSegmentedControl } from './ExportedSegmentedControl';
import { OrderSearchBarSelect } from './OrderSearchBarSelect';
import { GetOrdersParams } from 'features/order/api/useFetchOrders';

interface Props {
  params: GetOrdersParams;
  setParams: React.Dispatch<React.SetStateAction<GetOrdersParams>>;
  onResetFilters: () => void;
}

const OrderSearchBar = ({ params, setParams, onResetFilters }: Props) => {
  const { t } = useTranslation();

  const onFromDatetimeSelectChange = useCallback(
    (datetime: Date) => {
      setParams((_params) => ({
        ..._params,
        from_time: datetime ? Dayjs(datetime).format() : null,
      }));
    },
    [setParams],
  );

  const onTeamMemberSelectChange = useCallback(
    (value: string) => {
      setParams((_params) => ({ ..._params, assignee_id: value }));
    },
    [setParams],
  );

  const onToDatetimeSelectChange = useCallback(
    (datetime: Date) => {
      setParams((_params) => ({
        ..._params,
        to_time: datetime ? Dayjs(datetime).format() : null,
      }));
    },
    [setParams],
  );

  const onOrderStatusSelectChange = useCallback(
    (status: string) => {
      setParams((_params) => ({
        ..._params,
        status: doesExist(status, '') ? [status as OrderStatus] : null,
      }));
    },
    [setParams],
  );

  return (
    <div className="flex w-full justify-between items-end">
      <div className="items-end flex gap-smd">
        <ExportedSegmentedControl
          params={params}
          setParams={setParams}
        />

        <OrderSearchBarSelect
          value={(params.status || [])[0] || ''}
          data={[
            { value: '', label: 'All statuses' },
            { value: OrderStatus.New, label: 'New' },
            { value: OrderStatus.Confirmed, label: 'Confirmed' },
            { value: OrderStatus.Rejected, label: 'Rejected' },
            { value: OrderStatus.TimedOut, label: 'Timed out' },
          ]}
          onChange={onOrderStatusSelectChange}
          clearable
          size="xs"
        />

        <AssigneeSelect
          value={params.assignee_id || ''}
          onChange={onTeamMemberSelectChange}
        />

        <DatePickers
          fromDate={params.from_time ? Dayjs(params.from_time).toDate() : null}
          toDate={params.to_time ? Dayjs(params.to_time).toDate() : null}
          onFromDateChange={onFromDatetimeSelectChange}
          onToDateChange={onToDatetimeSelectChange}
        />
        <Button
          variant="outline"
          leftSection={<ArrowRedoIcon className="h-4 w-4 desktop:h-5 desktop:w-5" />}
          onClick={onResetFilters}
          radius="xl"
          size="xs"
          styles={{
            section: {
              marginRight: '4px',
            },
            label: {
              fontFamily: 'var(--font-screen-sm-regular-font-family)',
              fontSize: 'var(--font-screen-sm-regular-font-size)',
              fontStyle: 'var(--font-screen-sm-regular-font-style)',
              fontWeight: 'var(--font-screen-sm-regular-font-weight)',
              letterSpacing: 'var(--font-screen-sm-regular-letter-spacing)',
              lineHeight: 'var(--font-screen-sm-regular-line-height)',
            },
          }}
        >
          {t('order.list.searchBar.resetFilters')}
        </Button>
      </div>

      <NavLink to={ROUTES.PROCESS_NEW_ORDER_DRAFTS()}>
        <Button
          leftSection={<PlusIcon className="h-5 w-5" />}
          size="xs"
          radius="xl"
          styles={{
            section: {
              marginRight: '3px',
            },
            label: {
              fontFamily: 'var(--font-screen-sm-regular-font-family)',
              fontSize: 'var(--font-screen-sm-regular-font-size)',
              fontStyle: 'var(--font-screen-sm-regular-font-style)',
              fontWeight: 'var(--font-screen-sm-regular-font-weight)',
              letterSpacing: 'var(--font-screen-sm-regular-letter-spacing)',
              lineHeight: 'var(--font-screen-sm-regular-line-height)',
            },
          }}
        >
          {t('order.list.searchBar.manualOrder')}
        </Button>
      </NavLink>
    </div>
  );
};

export default OrderSearchBar;
