import { useCallback, useEffect, useState } from 'react';

import { Order } from '../models/Order';
import { Message, MessageRefType } from 'features/message/models/Message';

import { useFetchOrder } from '../api/useFetchOrder';
import { useFetchOrders } from '../api/useFetchOrders';

interface Props {
  message: Message;
  defaultIsLoading?: boolean;
}

const useFetchOrderByMessage = ({
  message,
  defaultIsLoading = true,
}: Props) => {
  const [order, setOrder] = useState<Order | null>(null);
  const [isLoading, setIsLoading] = useState(defaultIsLoading);
  const {
    loadOrder: loadOrderByOrderId,
  } = useFetchOrder({
    orderId: message?.ref,
    preventFetch: true,
  });

  const {
    setParams: setOrdersByGroupIdParams,
    loadOrders: loadOrdersByGroupId,
  } = useFetchOrders({ initialFetch: false, autoLoad: false });

  const loadOrder = useCallback(
    async () => {
      if (!message?.ref) return;

      setIsLoading(true);

      if (message?.refType === MessageRefType.ORDER_GROUP) {
        loadOrdersByGroupId()
          .then((orders) => {
            setOrder(orders?.[0]);
          })
          .finally(() => setIsLoading(false));
      } else {
        loadOrderByOrderId()
          .then((order_) => {
            setOrder(order_);
          })
          .finally(() => setIsLoading(false));
      }
    },
    [loadOrderByOrderId, loadOrdersByGroupId, message?.ref, message?.refType],
  );

  useEffect(() => {
    if (message?.refType === MessageRefType.ORDER_GROUP) {
      setOrdersByGroupIdParams({
        group_id: message.ref,
      });
    }
  }, [message?.ref, message?.refType, setOrdersByGroupIdParams]);

  return { order, isLoading, loadOrder };
};

export { useFetchOrderByMessage };
