import { useCallback, useEffect, useState } from 'react';

import { v4 as uuidv4 } from 'uuid';

import { Order } from '../models/Order';

import { genericErrorFeedback } from 'utils/errors';
import { httpGetV1 } from 'utils/xhr';

interface Props {
  orderId: string;
  preventFetch?: boolean;
}

const useFetchOrder = ({
  orderId,

  preventFetch = true,
}: Props) => {
  const [order, setOrder] = useState<Order | null>(null);
  const [isLoading, setIsLoading] = useState(true);

  const resetParams = () => {
    setOrder(null);
  };

  const loadOrder = useCallback(
    async (giveErrorFeedback: boolean = false): Promise<Order> => {
      if (!orderId) return Promise.reject(new Error('Order ID is required'));

      setIsLoading(true);
      return httpGetV1(`/orders/${orderId}`)
        .then((response) => {
          const responseData: Order = response.data;
          const order_ = {
            ...responseData,
            products: responseData.products?.map((p) => ({
              updatedName: p.name,
              uiId: uuidv4(),
              ...p,
            })),
            typeSpecs: responseData.typeSpecs?.map((ts) => ({
              ...ts,
              fields: ts.fields?.map((f) => ({
                ...f,
                uiId: uuidv4(),
              })),
            })),
          };
          setOrder(order_);
          return order_;
        })
        .catch((err) => {
          if (giveErrorFeedback) genericErrorFeedback('Error getting order details')(err);
          return Promise.reject(err);
        })
        .finally(() => setIsLoading(false));
    },
    [orderId],
  );

  useEffect(() => {
    if (!orderId || orderId === '') return () => {};

    if (preventFetch) return () => {};

    resetParams();

    const controller = new AbortController();

    loadOrder();

    return () => {
      controller.abort(); // Abort the fetch when orderId changes or component unmounts
    };
  }, [loadOrder, orderId, preventFetch]);

  return { order, isLoading, loadOrder };
};

export { useFetchOrder };
