import { Order, OrderStatus } from '../models/Order';

import { httpGetV1 } from 'utils/xhr';

type FetchOrdersByGroupIdParams = {
  group_id: string;
  status?: OrderStatus[];
};

export const fetchOrdersByGroupId = (groupId: string, onlyDrafts = true): Promise<Order[]> => {
  const params: FetchOrdersByGroupIdParams = {
    group_id: groupId,
  };

  if (onlyDrafts) {
    params.status = [OrderStatus.New];
  }

  return httpGetV1('/orders', {
    params,
  })
    .then((response) => {
      const responseOrders = (response.data.result || []) as Order[];

      return Promise.resolve(responseOrders);
    })
    .catch((error) => Promise.reject(error));
};
