import {
  useCallback, useMemo, useRef, useState,
} from 'react';
import { useTranslation } from 'react-i18next';

import isEqual from 'lodash/isEqual';
import { twMerge } from 'tailwind-merge';

import {
  faChevronDown,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Group } from 'features/instruction/constants';

import { FieldSpec, Schema } from 'features/instruction/models';
import { ProductWithQuantity } from 'features/order/models/Order';
import { Product, Unit } from 'features/product/models/Product';

import { QuantityConversionPopupContent } from 'features/product/types/product';

import { useSchema } from '../../../../hooks/useSchema';

import ProductPositionIdWrapper from '../utils/ProductPositionIdWrapper';
import { getErrorsOrWarnings, getProductFields } from 'features/order/store/process-order-drafts/utils';
import { convertQuantity, hasValidUnit } from 'features/product/utils/product';
import { isZeroId } from 'utils/objectId';

import CollapsedContent, { CollapsedContentRef } from './CollapsedContent';
import ExpandedContent from './ExpandedContent';
import ProductNameAndIcons from './ProductNameAndIcons';
import { useProcessOrderDraftsStore } from 'features/order/store/process-order-drafts';

interface Props {
  customerId: string;
  product: ProductWithQuantity;

  productNameField: FieldSpec | null;
  productQuantityField: FieldSpec | null;
  productUnitField: FieldSpec | null;
}

const filterRecordByUiId = (record: Record<Group, Record<string, string>>, uiId: string): string[] => (
  Object.entries(record[Group.Product])
    .filter(([key, error]) => key.startsWith(uiId) && error)
    .map(([, error]) => error)
);

const getItemId = (product: ProductWithQuantity) => (
  product.positionId && !isZeroId(product.positionId)
    ? product.positionId
    : product.uiId || product.name?.trim()
);

type MemoizedProductExpandableCardProps = {
  product: ProductWithQuantity;
  productErrors: string[];
  productWarnings: string[];
  id: string;
  removeProductByUiIdAndPositionId: (uiId: string, positionId?: string) => void;
  productFields: FieldSpec[];
  schema: Schema;
  customerId: string;
  productNameField: FieldSpec | null;
  productQuantityField: FieldSpec | null;
  productUnitField: FieldSpec | null;
  onProductUnitChange: (newUnit: Unit) => void;
  onProductQuantityChange: (val: number) => void;
  onProductSelectionChange: (p: Product) => void;
  updateProductFieldValueByPath: (path: string, val: any) => void;
  updateProduct: (values: any) => void;
  setError: (error: string, path: string) => void;

  quantityPopupContent: QuantityConversionPopupContent | null;
  setQuantityPopupContent: (quantityPopupContent: QuantityConversionPopupContent | null) => void;
  onQuantityPopupConfirmed: () => void;
  onQuantityPopupCanceled: () => void;
};

const deepEqual = (prevProps: MemoizedProductExpandableCardProps, nextProps: MemoizedProductExpandableCardProps) => {
  if (!isEqual(prevProps.productErrors, nextProps.productErrors)) {
    return false;
  }

  if (!isEqual(prevProps.productWarnings, nextProps.productWarnings)) {
    return false;
  }

  if (!isEqual(prevProps.product, nextProps.product)) {
    return false;
  }

  // Use Object.keys instead of for..in
  return Object.keys(prevProps).every((key) => {
    if (key === 'productErrors' || key === 'productWarnings' || key === 'product') return true;
    return prevProps[key as keyof MemoizedProductExpandableCardProps]
           === nextProps[key as keyof MemoizedProductExpandableCardProps];
  });
};

const MemoizedProductExpandableCard = React.memo(({
  product,
  productErrors,
  productWarnings,
  id,
  removeProductByUiIdAndPositionId,
  productFields,
  schema,
  customerId,
  productNameField,
  productQuantityField,
  productUnitField,
  quantityPopupContent,
  setQuantityPopupContent,
  onQuantityPopupConfirmed: onQuantityPopupConfirmed_,
  onQuantityPopupCanceled: onQuantityPopupCanceled_,
  onProductUnitChange,
  onProductQuantityChange,
  onProductSelectionChange,
  updateProductFieldValueByPath,
  updateProduct,
  setError,
}: MemoizedProductExpandableCardProps) => {
  const collapsedContentRef = useRef<CollapsedContentRef>(null);
  const [isExpanded, setIsExpanded] = useState(false);
  const [didLowQuantityConfirmed, setDidLowQuantityConfirmed] = useState(false);

  const onMouseEnter = useCallback(() => {
    // If unit is invalid then open unit select
    if (!hasValidUnit(product)) {
      collapsedContentRef.current?.openUnitSelect();
    }

    if (product.autoMatchedQuantity < 0.01 && product.autoMatchedQuantity === product.quantity && !didLowQuantityConfirmed) {
      setQuantityPopupContent({
        prevQuantity: 0,
        newQuantity: 0,
        alermLowQuantity: true,
      });
    }
  }, [collapsedContentRef, product, setQuantityPopupContent, didLowQuantityConfirmed]);

  const onMouseLeave = useCallback(() => {
    collapsedContentRef.current?.closeUnitSelect();
  }, [collapsedContentRef]);

  const onQuantityPopupConfirmed = useCallback(() => {
    setDidLowQuantityConfirmed(true);
    onQuantityPopupConfirmed_();
  }, [onQuantityPopupConfirmed_, setDidLowQuantityConfirmed]);

  const onQuantityPopupCanceled = useCallback(() => {
    setDidLowQuantityConfirmed(true);
    onQuantityPopupCanceled_();
  }, [onQuantityPopupCanceled_, setDidLowQuantityConfirmed]);

  return (
    <div
      className={twMerge(
        `bg-white relative p-4 rounded border ${productErrors.length > 0 && 'pb-5'}`,
      )}
      id={id}
      role="button"
      tabIndex={0}
      onKeyDown={null}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onClick={() => setIsExpanded(!isExpanded)}
    >
      <div className="flex items-center justify-between">
        <div className="flex flex-1 items-center justify-between">
          <ProductNameAndIcons
            product={product}
            productNameField={productNameField}
            removeProductByUiIdAndPositionId={removeProductByUiIdAndPositionId}
            errors={productErrors}
            warnings={productWarnings}
          />
        </div>
        <button
          type="button"
          onClick={() => setIsExpanded(!isExpanded)}
          className="ml-4 transition-transform duration-300"
        >
          <div className={`transform transition-transform duration-300 ${isExpanded ? 'rotate-180' : ''}`}>
            <FontAwesomeIcon icon={faChevronDown} />
          </div>
        </button>
      </div>

      {isExpanded ? (
        <ExpandedContent
          customerId={customerId}
          product={product}
          fieldSpecs={productFields}
          schema={schema}
          productNameField={productNameField}
          onProductUnitChange={onProductUnitChange}
          onProductSelectionChange={onProductSelectionChange}
          updateProductFieldValueByPath={updateProductFieldValueByPath}
          updateProduct={updateProduct}
          setError={setError}
          quantityPopupContent={quantityPopupContent}
          onQuantityPopupCanceled={onQuantityPopupCanceled}
          onQuantityPopupConfirmed={onQuantityPopupConfirmed}
        />
      ) : (
        <CollapsedContent
          ref={collapsedContentRef}
          customerId={customerId}
          product={product}
          productNameField={productNameField}
          productQuantityField={productQuantityField}
          productUnitField={productUnitField}
          onProductSelectionChange={onProductSelectionChange}
          onProductQuantityChange={onProductQuantityChange}
          onProductUnitChange={onProductUnitChange}
          setError={setError}
          quantityPopupContent={quantityPopupContent}
          onQuantityPopupConfirmed={onQuantityPopupConfirmed}
          onQuantityPopupCanceled={onQuantityPopupCanceled}
        />
      )}
    </div>
  );
}, (prevProps, nextProps) => deepEqual(prevProps, nextProps));

const ProductExpandableCard = ({
  customerId, product, productNameField, productQuantityField, productUnitField,
}: Props) => {
  const { t } = useTranslation();

  const groupOrders = useProcessOrderDraftsStore((state) => state.groupOrders);
  const selectedOrderId = useProcessOrderDraftsStore((state) => state.selectedOrderId);
  const storeErrors = useProcessOrderDraftsStore((state) => state.errors);
  const storeWarnings = useProcessOrderDraftsStore((state) => state.warnings);

  const updateProductByUiId = useProcessOrderDraftsStore((state) => state.updateProductByUiId);
  const updateProductByUiIdAndPath = useProcessOrderDraftsStore((state) => state.updateProductByUiIdAndPath);
  const removeProductByUiIdAndPositionId = useProcessOrderDraftsStore((state) => state.removeProductByUiIdAndPositionId);
  const addError = useProcessOrderDraftsStore((state) => state.addError);
  const removeError = useProcessOrderDraftsStore((state) => state.removeError);

  const setIsOrderPricesAlertVisible = useProcessOrderDraftsStore((state) => (
    state.setIsOrderPricesAlertVisible));

  const schema = useSchema({ groupOrders, selectedOrderId });
  const productFields = useMemo(() => getProductFields(groupOrders, selectedOrderId), [groupOrders, selectedOrderId]);

  const [quantityPopupContent, setQuantityPopupContent] = useState<QuantityConversionPopupContent | null>(null);

  const productErrors = useMemo(() => (
    filterRecordByUiId(getErrorsOrWarnings(storeErrors, selectedOrderId), product.uiId)
  ), [storeErrors, selectedOrderId, product.uiId]);
  const productWarnings = useMemo(() => (
    filterRecordByUiId(getErrorsOrWarnings(storeWarnings, selectedOrderId), product.uiId)
  ), [storeWarnings, selectedOrderId, product.uiId]);

  const id = getItemId(product);

  const setError = useCallback((error: string, path: string) => {
    const key = `${product.uiId}-${path}`;

    if (error) {
      addError(Group.Product, key, error);
    } else {
      removeError(Group.Product, key);
    }
  }, [addError, removeError, product.uiId]);

  const updateProduct = useCallback((values: any) => {
    updateProductByUiId(product.uiId, values);
  }, [product.uiId, updateProductByUiId]);

  const onLocalProductSelectionChange = useCallback((p: Product) => {
    setIsOrderPricesAlertVisible(true);
    return updateProduct({
      updatedName: p.name,
      id: p.id,
      score: p.score,
      product: p,
    });
  }, [updateProduct, setIsOrderPricesAlertVisible]);

  const updateLocalProductFieldValueByPath = useCallback((path: string, val: any) => {
    updateProductByUiIdAndPath(product.uiId, path, val);
  }, [product.uiId, updateProductByUiIdAndPath]);

  const onLocalProductQuantityChange = useCallback((val: number) => {
    updateLocalProductFieldValueByPath(productQuantityField?.path, val);

    if (typeof val !== 'number' || val <= 0) {
      setError(t('order.processOrderDrafts.rightSide.invalidQuantity'), productQuantityField?.path);
    } else {
      setError('', productQuantityField?.path);
    }
  }, [updateLocalProductFieldValueByPath, productQuantityField?.path, setError, t]);

  const onQuantityPopupConfirmed = useCallback(() => {
    if (quantityPopupContent) {
      onLocalProductQuantityChange(quantityPopupContent.newQuantity);
      setQuantityPopupContent(null);
    }
  }, [quantityPopupContent, onLocalProductQuantityChange]);

  const onQuantityPopupCanceled = useCallback(() => {
    setQuantityPopupContent(null);
  }, [setQuantityPopupContent]);

  const onLocalProductUnitChange = useCallback((newUnit: Unit) => {
    const newQuantity = convertQuantity(product.quantity, product.unit, newUnit);
    updateLocalProductFieldValueByPath(productUnitField?.path, newUnit);

    if (newQuantity !== product.quantity) {
      setQuantityPopupContent({
        prevQuantity: product.quantity,
        newQuantity,
      });
    }
  }, [product.quantity, product.unit, updateLocalProductFieldValueByPath,
    productUnitField?.path, setQuantityPopupContent]);

  return (
    <MemoizedProductExpandableCard
      product={product}
      productErrors={productErrors}
      productWarnings={productWarnings}
      id={id}
      removeProductByUiIdAndPositionId={removeProductByUiIdAndPositionId}
      productFields={productFields}
      schema={schema}
      customerId={customerId}
      productNameField={productNameField}
      productQuantityField={productQuantityField}
      productUnitField={productUnitField}
      onProductUnitChange={onLocalProductUnitChange}
      onProductQuantityChange={onLocalProductQuantityChange}
      onProductSelectionChange={onLocalProductSelectionChange}
      updateProductFieldValueByPath={updateLocalProductFieldValueByPath}
      updateProduct={updateProduct}
      setError={setError}
      quantityPopupContent={quantityPopupContent}
      setQuantityPopupContent={setQuantityPopupContent}
      onQuantityPopupConfirmed={onQuantityPopupConfirmed}
      onQuantityPopupCanceled={onQuantityPopupCanceled}
    />
  );
};

export default ProductPositionIdWrapper(ProductExpandableCard);
