import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';

import TypeWriter from 'typewriter-effect';

import { ROUTES } from 'config/routes';

import {
  Message, MessageIntent, MessageRefType, MessageStateType,
} from 'features/message/models/Message';

import adam from 'assets/adam-face.png';

import { hasPendingWorkflow } from 'features/message/utils/messageState';

interface ThreadMessageAdamSectionProps {
  message: Message;
  isExpanded: boolean;
  isAdamParticipating: boolean;
}

const ThreadMessageAdamSection = ({ message, isExpanded, isAdamParticipating }: ThreadMessageAdamSectionProps) => {
  const { t } = useTranslation();

  const orderRef = [MessageRefType.ORDER, MessageRefType.ORDER_GROUP]
    .includes(message.refType) ? message.ref : null;

  const isIntentExclusivelyOther = message.intents.length === 1 && message.intents[0] === MessageIntent.OTHER;

  if (message.state?.type === MessageStateType.NEW) {
    return (
      <div>
        <div className="mt-4 flex items-center space-x-2">
          <div className="rounded-full overflow-hidden">
            <img src={adam} alt="adam" className="h-8 w-8" />
          </div>
          <div className="text-start">
            <h3 className="font-semibold text-violet-900 text-sm">Adam</h3>
            <p className="text-xs text-violet-600">
              <TypeWriter
                onInit={(typewriter) => {
                  typewriter
                    .typeString(t('thread.adam.readingMessage'))
                    .pauseFor(1000)
                    .start();
                }}
                options={{
                  delay: 20,
                  loop: true,
                }}
              />
            </p>
          </div>
        </div>
      </div>
    );
  }

  if (isExpanded && hasPendingWorkflow(message, MessageIntent.ORDER)) {
    return (
      <div>
        <div className="mt-4 flex items-center space-x-2">
          <div className="rounded-full overflow-hidden">
            <img src={adam} alt="adam" className="h-8 w-8" />
          </div>
          <div className="text-start">
            <h3 className="font-semibold text-violet-900 text-sm">Adam</h3>
            <p className="text-xs text-violet-600">
              <TypeWriter
                onInit={(typewriter) => {
                  typewriter
                    .typeString(t('thread.adam.preparingOrder'))
                    .pauseFor(1000)
                    .start();
                }}
                options={{
                  delay: 20,
                  loop: true,
                }}
              />
            </p>
          </div>
        </div>
      </div>
    );
  }

  if (isExpanded && isAdamParticipating && orderRef) {
    return (
      <div>
        <div className="mt-4 flex items-center space-x-2">
          <div className="rounded-full overflow-hidden">
            <img src={adam} alt="adam" className="h-8 w-8" />
          </div>
          <div className="text-start">
            <h3 className="font-semibold text-violet-900 text-sm">Adam</h3>
            <p className="text-xs text-violet-600">
              {t('thread.adam.orderDraft')}
            </p>
          </div>
        </div>

        <div className="mt-2 ml-10">
          <NavLink
            className="w-fit"
            to={ROUTES.PROCESS_ORDER_DRAFT_BY_ID(orderRef, message.refType === MessageRefType.ORDER_GROUP)}
          >
            <button
              type="button"
              className="px-2 py-1 rounded-md bg-violet-500 hover:bg-violet-600 text-white text-xs inline-flex items-center"
            >
              {t('thread.adam.processOrderDraft')}
              {' '}
              →
            </button>
          </NavLink>
        </div>
      </div>
    );
  }

  if (isIntentExclusivelyOther && !orderRef) {
    return (
      <div>
        <div className="mt-4 flex items-center space-x-2">
          <div className="rounded-full overflow-hidden">
            <img src={adam} alt="adam" className="h-8 w-8" />
          </div>
          <div className="text-start">
            <h3 className="font-semibold text-violet-900 text-sm">Adam</h3>
            <p className="text-xs text-violet-600">
              {t('thread.adam.other')}
              <br />
              {t('thread.adam.youCanCreateOrderDraftManually')}
            </p>
          </div>
        </div>

        <div className="mt-2 ml-10 flex gap-2">
          <NavLink
            className="w-fit"
            to={ROUTES.PROCESS_NEW_ORDER_DRAFTS(message.id)}
          >
            <button
              type="button"
              className="px-2 py-1 rounded-md bg-violet-500 hover:bg-violet-600 text-white text-xs inline-flex items-center"
            >
              {t('thread.adam.createOrderDraftManually')}
              {' '}
              →
            </button>
          </NavLink>
        </div>
      </div>
    );
  }

  return null;
};

export default ThreadMessageAdamSection;
