import {
  useEffect, useImperativeHandle, useLayoutEffect, useRef,
} from 'react';

import { twMerge } from 'tailwind-merge';

import { Loader } from '@mantine/core';

import { Message } from 'features/message/models/Message';

import { MessageComposer } from '../../MessageComposer';
import AdamChatMessageBubble from './AdamChatMessageBubble';

interface Props {
  messages: Message[];
  hasNextPage: boolean;
  fetchNextPage: () => void;
  isAdamThinking: boolean;
  isFetchingMessages: boolean;

  onSendMessage: (message: string) => void;
}

interface MainViewRef {
  activateScrollToCurrentPosition: () => void;
  deactivateScrollToCurrentPosition: () => void;
}

const MainView = ({
  messages,
  hasNextPage,
  fetchNextPage,
  isAdamThinking,
  isFetchingMessages,
  onSendMessage,
}: Props, ref: React.Ref<MainViewRef>) => {
  const scrollToCurrentPosition = useRef(true);
  const scrollContainerRef = useRef(null);
  const prevScrollHeight = useRef(0);
  const isInitialLoad = useRef(true);

  const messagesFirstRef = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    const container = scrollContainerRef.current;
    if (isInitialLoad.current) {
      // Scroll to bottom on initial load
      container.scrollTop = container.scrollHeight;
      isInitialLoad.current = false;
    } else {
      if (!scrollToCurrentPosition.current) return;

      // Maintain scroll position after loading messages at the top
      container.scrollTop = container.scrollHeight - prevScrollHeight.current;
    }
  }, [messages]);

  useEffect(() => {
    // When messageFirstRef is in view, fetch next page
    const observer = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting && hasNextPage) {
        prevScrollHeight.current = scrollContainerRef.current.scrollHeight;
        fetchNextPage();
      }
    });
    observer.observe(messagesFirstRef.current);

    return () => observer.disconnect();
  }, [fetchNextPage, hasNextPage]);

  useImperativeHandle(ref, () => ({
    activateScrollToCurrentPosition: () => {
      scrollToCurrentPosition.current = true;
    },
    deactivateScrollToCurrentPosition: () => {
      scrollToCurrentPosition.current = false;
    },
  }));

  return (
    <div
      className={twMerge(
        'pt-4 pb-6 desktop:pb-8 desktop:pt-5 h-full flex flex-col gap-8 desktop:gap-10 items-center justify-between relative text-black-text-70',
        'font-font-screen-sm-regular font-[number:var(--font-screen-sm-regular-font-weight)] text-[length:var(--font-screen-sm-regular-font-size)] tracking-[var(--font-screen-sm-regular-letter-spacing)] leading-[var(--font-screen-sm-regular-line-height)] [font-style:var(--font-screen-sm-regular-font-style)]',
      )}
    >
      <div className="flex-1 w-full overflow-hidden relative">
        <div
          ref={scrollContainerRef}
          className="h-full w-full overflow-y-auto flex justify-center"
        >
          <div className="flex flex-col w-[900px] desktop:w-[1100px] items-start gap-10 desktop:gap-16">
            <div className={twMerge(
              'absolute top-0 left-1/2 -translate-x-1/2 w-fit h-fit flex items-center justify-center bg-white rounded-full transition-all duration-300 ease-in-out py-1 px-6 shadow-blue-light-tint-drop-shadow border border-light-grey',
              isFetchingMessages ? 'translate-y-0' : '-translate-y-10',
            )}
            >
              <Loader size="sm" />
            </div>

            <div ref={messagesFirstRef} />

            {messages.map((message) => (
              <AdamChatMessageBubble key={message.id} message={message} />
            ))}

            {isAdamThinking && (
            <div className="flex items-start gap-[13px] relative self-stretch w-full flex-[0_0_auto]">
              <Loader type="dots" size="md" />
            </div>
            )}
          </div>
        </div>
      </div>

      <div className="w-[700px] desktop:w-[1000px]">
        <MessageComposer className="px-3 py-2" onSendMessage={onSendMessage} />
      </div>
    </div>
  );
};

export default React.forwardRef(MainView);
