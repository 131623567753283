import { forwardRef, memo } from 'react';

import { startOfToday } from 'date-fns';
import Dayjs from 'dayjs';
import { twMerge } from 'tailwind-merge';

import { Tooltip } from '@mantine/core';

import { globalUser } from 'state/globalUser';

import { MessageIntent } from 'features/message/models/Message';
import { User } from 'features/user/models';
import { Thread } from 'models/Thread';

import { Tag } from 'components/common/Tag';
import { OverflowEllipsisParagraph } from 'components/ui/OverflowEllipsisParagraph';

import { decodeEntities } from 'features/message/utils/message';
import { isZeroTime } from 'utils/dateTime';
import { intentToTagVariant, sortIntents } from 'utils/enums';

interface Props {
  thread: Thread;
  selectedThreads: Thread[];
  setSelectedThreads: (threads: Thread[]) => void;
  onThreadRowClick: (thread: Thread) => void;
  priorityIntent: MessageIntent;
}

const formatDatetime = (thread: Thread) => {
  const now = startOfToday();
  if (isZeroTime(thread.lastMessageCreatedAt) && isZeroTime(thread.createdAt)) {
    return '-';
  }

  const messageDatetime = Dayjs(thread.lastMessageCreatedAt || thread.createdAt);

  if (messageDatetime.isSame(now, 'day')) {
    return messageDatetime.format('LT');
  }

  return messageDatetime.format('MMM D');
};

const getParticipantsText = (thread: Thread) => {
  const getParticipantText = (user: User) => {
    if (user.id === globalUser.id) {
      return 'me';
    }

    const fullName = [user.firstName, user.lastName]
      .filter(Boolean)
      .join(' ');
    return fullName || user.GetEmail() || user.GetPhone();
  };

  const participants = thread.participantUsers?.sort((a) => (a.id === globalUser.id ? -1 : 0))
    .map((user) => getParticipantText(user));

  const participantsText = participants.join(', ').trim();

  if (participantsText.length <= 20) {
    return participantsText;
  }

  if (participants.length === 1) {
    return `${participantsText.slice(0, 19)}.`;
  }

  const firstParticipant = participants[0];
  const firstParticipantText = `${firstParticipant} .. `;

  let lastParticipantsText = '';
  [...participants].reverse().every((p) => {
    const separator = lastParticipantsText.length > 0 ? ', ' : '';
    if (firstParticipantText.length + p.length + separator.length + lastParticipantsText.length <= 20) {
      lastParticipantsText = `${p}${separator}${lastParticipantsText}`;
      return true;
    }
    return false;
  });

  if (lastParticipantsText.length === 0) {
    lastParticipantsText = `${participants[participants.length - 1].slice(0, 20 - firstParticipantText.length - 1)}.`;
  }

  return `${firstParticipantText}${lastParticipantsText}`;
};

const TableRow = forwardRef(({
  thread,
  selectedThreads,
  setSelectedThreads,
  onThreadRowClick,
  priorityIntent,
}: Props, ref: React.Ref<HTMLTableRowElement>) => {
  const threadSortedIntents = sortIntents(thread.intents, priorityIntent);

  return (
    <tr
      ref={ref}
      key={thread.id}
      className={twMerge(
        'cursor-pointer bg-white',
        selectedThreads.includes(thread) || !isZeroTime(thread.readAt)
          ? 'bg-gray-100'
          : 'hover:bg-gray-50',
      )}
      onClick={() => onThreadRowClick(thread)}
    >
      <td className="relative px-7 sm:w-12 sm:px-xl">
        {selectedThreads.includes(thread) && (
        <div className="absolute inset-y-0 left-0 w-0.5 bg-indigo-600" />
        )}
        <input
          type="checkbox"
          className="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
          value={thread.id}
          checked={selectedThreads.includes(thread)}
          onChange={(e) => setSelectedThreads(
            e.target.checked
              ? [...selectedThreads, thread]
              : selectedThreads.filter((p) => p !== thread),
          )}
          onClick={(e) => e.stopPropagation()}
        />
      </td>
      <td className="px-lg">
        <div className="items-center">
          <div className="text-gray-900 flex items-center whitespace-nowrap overflow-hidden">
            <span className="truncate">{getParticipantsText(thread)}</span>
            {thread.length > 1 && (
            <span className="ml-2 text-gray-500 text-xs font-semibold shrink-0">
              {thread.length}
            </span>
            )}
          </div>
        </div>
      </td>
      <td className="px-lg">
        <div className="flex items-center gap-1">
          {threadSortedIntents && threadSortedIntents.length > 0 && (
          <>
            <Tag
              tagTitle={threadSortedIntents[0] || '-'}
              className="static"
              tagVariant={intentToTagVariant(threadSortedIntents[0])}
              hideCircle
            />
            {threadSortedIntents.length > 1 && (
            <Tooltip
              label={(
                <div className="flex flex-col gap-2">
                  {threadSortedIntents?.map((intent) => (
                    <Tag
                      key={intent}
                      tagTitle={intent || '-'}
                      className="static"
                      tagVariant={intentToTagVariant(intent)}
                      hideCircle
                    />
                  ))}
                </div>
                  )}
              transitionProps={{ transition: 'pop' }}
              bg="white"
              position="bottom"
              styles={{
                tooltip: {
                  boxShadow: '0 1px 2px 0 rgb(0 0 0 / 0.05)',
                },
              }}
            >
              <div className="flex aspect-square h-fit items-center justify-center rounded-full border border-gray-200 px-1 text-xxs text-gray-500">
                +
                {threadSortedIntents.length - 1}
              </div>
            </Tooltip>
            )}
          </>
          )}
        </div>
      </td>
      <td className="w-full px-lg py-smd text-sm text-gray-500">
        <div className="font-bold text-gray-900">
          {thread.firstMessageSubject || 'No subject'}
        </div>
        <div className="max-w-lg">
          <OverflowEllipsisParagraph maxLines={1}>
            <div className="text-gray-500">{decodeEntities(thread.lastMessageText)}</div>
          </OverflowEllipsisParagraph>
        </div>
      </td>
      <td className="whitespace-nowrap px-lg py-smd pr-7 text-sm text-gray-500">
        {formatDatetime(thread)}
      </td>
    </tr>
  );
});

export default memo(TableRow);
