import { useCallback, useMemo } from 'react';

import { ExclamationCircleIcon } from '@heroicons/react/24/outline';
import { rem, TextInput } from '@mantine/core';

import { ProductWithQuantity } from 'features/order/models/Order';

import { OverflowEllipsisParagraph } from 'components/ui/OverflowEllipsisParagraph';

interface Props {
  label: string;
  productWithQuantity: ProductWithQuantity;
  onValueChange?: (value: string) => void;
}

const IdOrSkuInput = ({
  label, productWithQuantity, onValueChange,
}: Props) => {
  const showErrorIcon = useMemo(() => (
    productWithQuantity?.autoMatchedIdOrSku
    && (productWithQuantity?.autoMatchedIdOrSku !== productWithQuantity?.product?.idOrSku)
  ), [productWithQuantity?.autoMatchedIdOrSku, productWithQuantity?.product?.idOrSku]);

  const onTextInputChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    onValueChange?.(e.target.value);
  }, [onValueChange]);
  return (
    <TextInput
      label={label}
      value={productWithQuantity?.autoMatchedIdOrSku || ''}
      size="sm"
      variant="unstyled"
      placeholder="No ID or SKU were extracted"
      readOnly
      onChange={onTextInputChange}
      styles={{
        input: {
          borderBottom: '1px solid #A1A3AB',
          borderRadius: '0',
        },
      }}
      className="w-full"
      rightSection={showErrorIcon && (
        <OverflowEllipsisParagraph
          isTooltipEnabled
          customTooltipContent={(
            <div>
              Adam has extracted this
              {' '}
              {label}
              , which is different from the one of the matched product.
              <br />
              Please check the product and update the value if needed.
            </div>
)}
          theme="warning"
        >
          <ExclamationCircleIcon
            style={{ width: rem(20), height: rem(20) }}
            color="var(--mantine-color-error)"
          />
        </OverflowEllipsisParagraph>
      )}
    />
  );
};

export default IdOrSkuInput;
