import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { twMerge } from 'tailwind-merge';

import { Loader } from '@mantine/core';

import { Order } from 'features/order/models/Order';

import { useExportOrders } from 'features/order/hooks';

import ArrowExportIcon from 'components/icon/outline/ArrowExportIcon';

import { OrderListTable, OrderListTableProps } from './OrderListTable';

interface Props extends OrderListTableProps {
  orders: Order[];
  setOrders: (orders: Order[]) => void;
}

const OrderListTableWithExportButton = ({ orders, setOrders, ...props }: Props) => {
  const { t } = useTranslation();
  const { isLoading, exportOrders } = useExportOrders();

  const [selectedOrderIds, setSelectedOrderIds] = useState<string[]>([]);

  const onExportClick = useCallback(() => {
    exportOrders(selectedOrderIds)
      .then(() => {
        setOrders(orders.map((o) => {
          if (selectedOrderIds.includes(o.id)) {
            return { ...o, isExported: true };
          }
          return o;
        }));
      });
  }, [exportOrders, selectedOrderIds, orders, setOrders]);

  return (
    <>
      <OrderListTable
        {...props}
        orders={orders}
        selectedOrderIds={selectedOrderIds}
        setSelectedOrderIds={setSelectedOrderIds}
      />

      <div className="inline-flex items-end justify-start gap-2 relative flex-1 grow">
        <button
          type="button"
          disabled={selectedOrderIds.length === 0 || isLoading}
          onClick={onExportClick}
          className={twMerge(
            'inline-flex items-center justify-center gap-1 px-4 py-2 relative flex-[0_0_auto] bg-purple-highlight-2 rounded-full shadow-blue-light-tint-drop-shadow hover:shadow-blue-tint-drop-shadow',
            isLoading && 'cursor-wait',
            selectedOrderIds.length === 0 && 'opacity-50 cursor-not-allowed',
          )}
        >
          {
            isLoading ? (
              <Loader size={16} color="white" />
            ) : (
              <ArrowExportIcon className="!relative !w-5 !h-5" color="white" />
            )
          }
          <div
            className="relative w-fit font-font-screen-md-regular font-[number:var(--font-screen-md-regular-font-weight)] text-white text-[length:var(--font-screen-md-regular-font-size)] tracking-[var(--font-screen-md-regular-letter-spacing)] leading-[var(--font-screen-md-regular-line-height)] whitespace-nowrap [font-style:var(--font-screen-md-regular-font-style)]"
          >
            {t('order.list.export')}
          </div>
        </button>
      </div>
    </>
  );
};

export default OrderListTableWithExportButton;
