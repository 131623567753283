import { useState } from 'react';

import { twMerge } from 'tailwind-merge';

import { Loader, Textarea } from '@mantine/core';

import { ArrowRightIcon } from 'components/icon/outline';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  onSendMessage: (text: string) => void;
  isLoading?: boolean;
}

const MessageComposer = ({ onSendMessage, isLoading, ...props }: Props) => {
  const [message, setMessage] = useState('');

  const handleSendMessage = () => {
    if (message.trim()) {
      onSendMessage(message);
      setMessage('');
    }
  };

  return (
    <div
      {...props}
      className={twMerge(
        'w-full h-fit rounded-md shadow-md bg-white flex flex-col p-4',
        props.className,
      )}
    >
      <Textarea
        autosize
        minRows={2}
        maxRows={5}
        variant="unstyled"
        className="focus:ring-0 focus:border-none focus:outline-none border-none ring-0"
        styles={{
          input: {
            border: 'none',
            boxShadow: 'none',
            transition: 'none',
          },
        }}
        placeholder="Shift + Enter to new line"
        value={message}
        onChange={(e) => setMessage(e.currentTarget.value)}
        onKeyDown={(e) => {
          if (e.key === 'Enter' && !e.shiftKey) {
            e.preventDefault();
            handleSendMessage();
          }
        }}
      />
      <div className="flex justify-end">
        <button
          type="button"
          className={twMerge(
            'w-8 h-8 desktop:w-10 desktop:h-10 rounded-full flex justify-center items-center text-white',
            message.length > 0 ? 'bg-purple-highlight-2' : 'bg-purple-highlight-3',
          )}
          onClick={handleSendMessage}
          disabled={isLoading}
        >
          {
            isLoading ? (
              <Loader size={24} color="white" />
            ) : (
              <ArrowRightIcon />
            )
          }
        </button>
      </div>
    </div>
  );
};

export default MessageComposer;
