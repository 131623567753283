import adam from 'assets/adam-face.png';

import { MessageComposer } from '../../MessageComposer';

const examplePrompts = [
  'What is the current price and stock level for Banane?',
  'Has Customer placed an order in the last month?',
  'Check if [Customer Name] has any unpaid invoices. If they do, draft a polite payment reminder.',
  'What are the top-selling products for the past three months? Show me trends for [Product Name]',
];

interface Props {
  onSendMessage: (message: string) => void;
  isLoading: boolean;
}

const LandingView = ({ onSendMessage, isLoading }: Props) => (
  <div className="w-full h-full flex justify-center items-center">
    <div className="flex flex-col items-center">
      <div className="flex flex-col items-center gap-3 desktop:gap-5">
        <img
          src={adam}
          alt="adam"
          className="w-28 h-28 rounded-full desktop:w-32 desktop:h-32"
        />
        <p className="text-3xl desktop:text-4xl font-medium text-gray-500">
          What can I help you with?
        </p>
      </div>
      <p className="text-sm desktop:text-base text-gray-400 mt-2 desktop:mt-3">
        Choose a prompt below or write your own to start chatting with Adam
      </p>

      <div className="mt-6 w-[700px] desktop:w-[1000px]">
        <MessageComposer onSendMessage={onSendMessage} isLoading={isLoading} />

        <div className="flex flex-wrap gap-2 mt-3">
          {examplePrompts.map((prompt) => (
            <p
              key={prompt}
              className="bg-gray-100 text-gray-400 rounded-md py-2 px-4 text-xs"
            >
              {prompt}
            </p>
          ))}
        </div>
      </div>
    </div>
  </div>
);

export default LandingView;
