import { StateCreator } from 'zustand';

import * as PROMPT from 'features/instruction/constants';

import { ProductWithQuantity } from 'features/order/models/Order';
import { Unit } from 'features/product/models/Product';

import { MagicPenSlice, ProcessOrderDraftsStore } from './types';

import { QuerySearchType } from 'hooks/fetch/useFetchProducts';

import { getGroupedFields, getProductGroupKey } from './utils';
import { getGroupKeyByPath } from 'features/instruction/utils';

import { fetchProducts, FetchProductsParams } from 'features/product/api';

export const createMagicPenSlice: StateCreator<ProcessOrderDraftsStore, [], [], MagicPenSlice> = (set, get) => ({
  addNewParsedProducts: async (fields: Record<string, any>[], orderId_?: string) => {
    const orderId = orderId_ || get().selectedOrderId;
    const order = get().groupOrders[orderId];

    const newProducts: ProductWithQuantity[] = await Promise.all(fields.map(async (field: Record<string, any>) => {
      const newProduct = new ProductWithQuantity({
        name: field[PROMPT.ORDER_DRAFT_PWQ_NAME_PATH],
        autoMatchedIdOrSku: field[PROMPT.ORDER_DRAFT_PWQ_IDorSKU_PATH],
        quantity: parseFloat(field[PROMPT.ORDER_DRAFT_PWQ_QUANTITY_PATH]),
        unit: new Unit({
          symbol: field[PROMPT.ORDER_DRAFT_PWQ_UNIT_PATH],
        }),
        autoMatchedUnit: new Unit({
          symbol: field[PROMPT.ORDER_DRAFT_PWQ_UNIT_PATH],
        }),
      });

      if (!newProduct.quantity) {
        return null;
      }

      let matchedById = false;
      let products = [];
      if (newProduct.autoMatchedIdOrSku) {
        try {
          const { result: fetchedProducts } = await fetchProducts({
            searchQuery: newProduct.autoMatchedIdOrSku,
            customerId: order?.createdBy,
            querySearchType: QuerySearchType.Standard,
            filterEnabledProducts: true,
            reset: true,
          } as FetchProductsParams);

          products = fetchedProducts;
          matchedById = products?.length > 0;
        } catch {
          /* do nothing */
        }
      }

      if (!matchedById) {
        try {
          const { result: fetchedProducts } = await fetchProducts({
            searchQuery: newProduct.name,
            customerId: order?.createdBy,
            querySearchType: QuerySearchType.Embedding,
            filterEnabledProducts: true,
            reset: true,
          } as FetchProductsParams);

          products = fetchedProducts;
          const product = products?.[0];
          newProduct.product = product;
          newProduct.id = product?.id;
        } catch {
          /* do nothing */
        }
      }

      get().addNewProduct(newProduct, orderId);
      return newProduct;
    }));

    return newProducts.filter((product) => product !== null);
  },
  addParsedNonProductFields: (fields: Record<string, any>[], orderId_?: string) => {
    const { groupOrders, selectedOrderId, schemas } = get();
    const nonProductValues: string[] = [];

    type Fields = Record<string, any>[];

    // Group fields by group key
    const keyAndFields: Record<string, Fields> = fields.reduce(
      (acc: Record<string, Fields>, field: Record<string, any>) => {
        const paths = Object.keys(field) || [];
        if (paths.length === 0) return acc;

        let groupKey = '';

        paths.every((path) => {
          const _groupKey = getGroupKeyByPath(path, getGroupedFields(groupOrders, selectedOrderId, schemas));
          if (_groupKey) {
            groupKey = _groupKey;
            return false;
          }
          return true;
        });

        if (!groupKey) return acc;

        if ((Object.keys(acc) || []).includes(groupKey)) {
          acc[groupKey].push(field);
        } else {
          acc[groupKey] = [field];
        }

        return acc;
      }, {},
    );

    const productGroupKey = getProductGroupKey(groupOrders, selectedOrderId, schemas);

    Object.entries(keyAndFields).forEach(([groupKey, _fields]) => {
      if (groupKey === productGroupKey) return;

      _fields.forEach((field) => {
        Object.keys(field).forEach((key) => {
          const value = field[key];
          get().updateValueByPath(value, key.replace('order_drafts.*.', ''), undefined, orderId_);
          nonProductValues.push(value);
        });
      });
    });
    return nonProductValues;
  },
});
