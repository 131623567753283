import {
  ChangeEvent, useCallback, useEffect, useState,
} from 'react';

import { Order, ProductWithQuantity } from '../../../models/Order';

import { setValueByPath } from 'features/instruction/utils';

import { useOrderContext } from '../../useOrderContext';

type useOrderReturnType = {
  order: Order | null;
  updateOrderByFn: (updateFn: (currentOrder: Order) => Order) => void;

  updateValueByPath: (value: any, path: string, indices?: number[]) => void;
  onCommentChange: (event: ChangeEvent<HTMLTextAreaElement>) => void;
  onOrderDraftsSaved: (orders: Order[]) => void;
  onTabChange: (index: number) => void;
  // TODO: remove those props
  selectedIndex: number;
  setGroupOrders: React.Dispatch<React.SetStateAction<Order[]>>;
};

interface UseOrderProps {
  updateGlobalCurrentOrder: (order: Order) => void;
}

export const useOrder = ({ updateGlobalCurrentOrder }: UseOrderProps): useOrderReturnType => {
  const {
    groupOrders, setGroupOrders, selectedIndex, setSelectedIndex,
  } = useOrderContext();

  const [order, setOrder] = useState<Order | null>(null);

  useEffect(() => {
    const currentOrder = groupOrders?.[selectedIndex];
    if (!currentOrder) return;

    setOrder(currentOrder);
  }, [groupOrders, selectedIndex]);

  const updateOrderByFn = useCallback((updateFn: (currentOrder: Order) => Order) => {
    setOrder((prevOrder) => updateFn(prevOrder));
  }, [setOrder]);

  const updateValueByPath = useCallback((value: any, path: string, indices: number[] = []) => {
    setOrder((prevOrder) => {
      const newOrder = setValueByPath(prevOrder, value, path, indices);
      return {
        ...newOrder,
        // dirty way to force re-render of the product field
        products: (newOrder.products || []).map((product: ProductWithQuantity) => ({ ...product })),
        didChangeMade: true,
      };
    });
  }, [setOrder]);

  const onOrderDraftsSaved = useCallback((orders: Order[]) => {
    setGroupOrders(orders.map((_order) => ({
      ..._order,
      didChangeMade: false,
      isCreated: false,
    })));

    updateGlobalCurrentOrder({
      ...orders[0],
      didChangeMade: false,
      isCreated: false,
    });

    setOrder((prevOrder) => ({
      ...prevOrder,
      didChangeMade: false,
      isCreated: false,
    }));
  }, [setGroupOrders, updateGlobalCurrentOrder]);

  const onCommentChange = useCallback(async (event: ChangeEvent<HTMLTextAreaElement>) => {
    setGroupOrders((prevGos) => prevGos.map((prevOrder, i) => (i === selectedIndex
      ? {
        ...prevOrder,
        draft: { ...prevOrder.draft, comment: event.target.value },
        didChangeMade: true,
      }
      : prevOrder),
    ),
    );
  }, [selectedIndex, setGroupOrders]);

  const onTabChange = useCallback((index: number) => {
    setGroupOrders((prevGos) => prevGos.map((prevOrder, i) => (
      i === selectedIndex ? order : prevOrder
    )));

    setSelectedIndex(index);
  }, [order, selectedIndex, setGroupOrders, setSelectedIndex]);

  return {
    order,
    updateOrderByFn,
    updateValueByPath,
    onCommentChange,
    onOrderDraftsSaved,
    onTabChange,
    selectedIndex,
    setGroupOrders,
  };
};
