import {
  useCallback, useEffect, useRef,
  useState,
} from 'react';

import { reaction } from 'mobx';
import { observer } from 'mobx-react-lite';
import { twMerge } from 'tailwind-merge';

import { Popover } from '@mantine/core';

import { globalLROs } from 'state/globalLROs';
import { globalNotifications } from 'state/globalNotifications';

import { BellIcon } from 'components/icon/outline';

import { genericErrorFeedback } from 'utils/errors';

import { LroList } from './LroList';
import { NotificationList } from './NotificationList';

const NotificationButton = observer(() => {
  const [unreadCount, setUnreadCount] = useState(globalNotifications.unreadCount);

  const prevCount = useRef(globalNotifications.unreadCount);
  const badgeRef = useRef<HTMLSpanElement>(null);

  useEffect(() => {
    if (unreadCount !== prevCount.current && badgeRef.current) {
      badgeRef.current.classList.add('scale-125');

      setTimeout(() => {
        badgeRef.current?.classList.remove('scale-125');
      }, 200);

      prevCount.current = unreadCount;
    }
  }, [unreadCount]);

  reaction(
    () => globalNotifications.unreadCount,
    () => {
      setUnreadCount(globalNotifications.unreadCount);
    },
  );

  const handleMarkAllAsRead = useCallback(() => {
    try {
      globalNotifications.markAsRead([]);
    } catch (error) {
      genericErrorFeedback('Failed to mark all notifications as read')(error);
    }
  }, []);

  return (
    <Popover
      withArrow
    >
      <Popover.Target>
        {
          globalLROs.pendingCount > 0 ? (
            <div className="relative flex items-center justify-center w-10 h-10 cursor-pointer">
              {/* Outer spinning circle */}
              <div className="absolute w-10 h-10 border-2 border-blue-200 rounded-full animate-spin border-t-blue-500" />

              {/* Inner static bell icon */}
              <div className="absolute">
                <div className="inline-flex items-center justify-center p-2 relative flex-[0_0_auto] bg-white rounded-full overflow-hidden border border-solid border-light-grey cursor-pointer">
                  <BellIcon className="!relative !w-5 !h-5" />
                </div>
              </div>
            </div>
          ) : (
            <div className="relative">
              <div className="inline-flex items-center justify-center p-1.5 desktop:p-2 relative flex-[0_0_auto] bg-white rounded-full overflow-hidden border border-solid border-light-grey shadow-blue-light-tint-drop-shadow hover:shadow-blue-tint-drop-shadow cursor-pointer">
                <BellIcon className="!relative size-[18px] desktop:size-5" />
              </div>

              {globalNotifications.unreadCount > 0 && (
                <span
                  ref={badgeRef}
                  className={twMerge(
                    'absolute right-0 -top-0.5 w-3 h-3 rounded-full bg-purple-highlight-2',
                  )}
                />
              )}
            </div>
          )
        }
      </Popover.Target>

      <Popover.Dropdown>
        <div className="w-[300px] 4xl:w-[500px] flex-auto overflow-hidden text-sm leading-6 space-y-2">
          <div className="flex items-center justify-between py-1">
            <h3 className="font-semibold text-gray-900">Notifications</h3>
            <button
              type="button"
              onClick={handleMarkAllAsRead}
              className="text-primary-600 hover:text-primary-700"
            >
              Mark all as read
            </button>
          </div>

          <div className="max-h-[50vh] overflow-y-auto divide-y divide-gray-200 border-t border-t-gray-300 px-2 -mx-2">
            <LroList />
            <NotificationList />
          </div>
        </div>
      </Popover.Dropdown>
    </Popover>
  );
});

export default NotificationButton;
