import { useRef } from 'react';

import { EnvelopeIcon, EnvelopeOpenIcon } from '@heroicons/react/24/outline';
import { ArrowPathIcon } from '@heroicons/react/24/solid';
import { Tooltip } from '@mantine/core';

import { Thread } from 'models/Thread';

import { ThreadsFilter } from 'hooks/fetch/useFetchThreads';

import { isZeroTime } from 'utils/dateTime';

import ThreadFilter from './ThreadFilter';

interface Props {
  allSelected: boolean;
  toggleAll: () => void;
  reload: () => void;
  toggleThreadsReadStatus: () => void;
  selectedThreads: Thread[];
  threadsFilter: ThreadsFilter;
  setThreadsFilter: (filter: ThreadsFilter) => void;
}

const TableHeader = ({
  allSelected,
  toggleAll,
  reload,
  toggleThreadsReadStatus,
  selectedThreads,
  threadsFilter,
  setThreadsFilter,
}: Props) => {
  const checkbox = useRef<HTMLInputElement>();

  const getEnvelopeIcon = () => {
    if (threadsFilter.unreadOnly) {
      return <EnvelopeOpenIcon className="h-5 w-5 text-gray-600" />;
    }

    const hasUnreadThreads = selectedThreads.some((t) => isZeroTime(t.readAt));
    return hasUnreadThreads ? (
      <EnvelopeOpenIcon className="h-5 w-5 text-gray-600" />
    ) : (
      <EnvelopeIcon className="h-5 w-5 text-gray-600" />
    );
  };

  const getEnvelopeIconTooltip = () => {
    if (threadsFilter.unreadOnly) {
      return 'Mark as read';
    }

    const hasUnreadThreads = selectedThreads.some((t) => isZeroTime(t.readAt));
    return hasUnreadThreads ? 'Mark as read' : 'Mark as unread';
  };

  return (
    <div className="sticky top-0 z-10 border-b border-gray-300 bg-white drop-shadow-sm">
      <div className="flex items-center gap-3 py-2">
        <div className="relative px-5">
          <input
            type="checkbox"
            className="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
            ref={checkbox}
            checked={allSelected}
            onChange={toggleAll}
          />
        </div>
        <Tooltip
          label="Refresh"
          transitionProps={{
            transition: 'pop',
            duration: 200,
            enterDelay: 100,
          }}
          bg="white"
          styles={{
            tooltip: {
              color: 'black',
              border: '1.5px solid #E8E8E8',
              boxShadow: '0 1px 2px 0 rgb(0 0 0 / 0.05)',
            },
          }}
        >
          <button
            type="button"
            onClick={reload}
            className="rounded-full p-2 transition-colors delay-100 duration-200 hover:bg-gray-100"
          >
            <ArrowPathIcon className="h-5 w-5 text-gray-600" />
          </button>
        </Tooltip>
        {selectedThreads.length > 0 && (
          <Tooltip
            label={getEnvelopeIconTooltip()}
            transitionProps={{
              transition: 'pop',
              duration: 200,
              enterDelay: 100,
            }}
            bg="white"
            styles={{
              tooltip: {
                color: 'black',
                border: '1.5px solid #E8E8E8',
                boxShadow: '0 1px 2px 0 rgb(0 0 0 / 0.05)',
              },
            }}
          >
            <button
              type="button"
              onClick={toggleThreadsReadStatus}
              className="rounded-full p-2 transition-colors delay-100 duration-200 hover:bg-gray-100"
            >
              {getEnvelopeIcon()}
            </button>
          </Tooltip>
        )}
        <ThreadFilter
          setThreadsFilter={setThreadsFilter}
          defaultFilter={threadsFilter}
        />
      </div>
    </div>
  );
};

export default TableHeader;
