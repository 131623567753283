import { Order, OrderStatus } from '../models/Order';

import { parsePaginatedApiResponse } from 'utils';
import { httpGetV1 } from 'utils/xhr';

export interface FetchOrdersParams {
  is_not_exported?: boolean;
  status?: OrderStatus[];
  from_time?: string;
  to_time?: string;
  group_id?: string;
  assignee_id?: string;

  cursor?: string;
}

export const fetchOrders = (params: FetchOrdersParams) => httpGetV1('/orders', {
  params,
  classType: Order,
}).then((response) => parsePaginatedApiResponse<Order>(response));
