import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import { twMerge } from 'tailwind-merge';

import { faCirclePlay } from '@fortawesome/free-regular-svg-icons';
import {
  faExclamationCircle,
  faMagnifyingGlass,
  faTrashCan,
  faTriangleExclamation,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { FieldSpec } from 'features/instruction/models';
import { ProductWithQuantity } from 'features/order/models/Order';

import { Tooltip } from 'components/ui';
import { OverflowEllipsisParagraph } from 'components/ui/OverflowEllipsisParagraph';

import { FieldPathWrapper } from '../utils';
import { wasMatchedByID } from 'features/product/utils/product';
import { formatPrice } from 'utils/formatting';

import ErrorsTooltip from '../ErrorsTooltip';
import WarningsTooltip from '../WarningsTooltip';
import ConfidenceLevelIndicator from './ConfidenceLevelIndicator';
import { useProcessOrderDraftsStore } from 'features/order/store/process-order-drafts';

interface Props {
  product: ProductWithQuantity;
  productNameField: FieldSpec;
  removeProductByUiIdAndPositionId: (uiId: string, positionId: string) => void;

  errors: string[];
  warnings: string[];
}

const WrappedP = FieldPathWrapper(({ children }: { children: ReactNode }) => <p className="text-sm">{children}</p>);
const IconButtonWrappedP = FieldPathWrapper(({ children }: { children: ReactNode }) => (
  <div className="w-6 h-6 flex items-center justify-center text-gray-500">{children}</div>),
);

const ProductNameAndIcons = ({
  product, productNameField, removeProductByUiIdAndPositionId, errors, warnings,
}: Props) => {
  const { t } = useTranslation();

  const isRecordingAvailable = useProcessOrderDraftsStore((state) => state.isRecordingAvailable);
  const setPlayRecordingKeywordAndMessageId = useProcessOrderDraftsStore(
    (state) => state.setPlayRecordingKeywordAndMessageId,
  );
  const selectedMessageId = useProcessOrderDraftsStore((state) => state.message)?.id;

  const isPriceLoading = useProcessOrderDraftsStore((state) => state.isOrderPricesLoading);

  const onPlayButtonClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    setPlayRecordingKeywordAndMessageId({
      keyword: product.positionId,
      messageId: selectedMessageId,
    });
  };

  const onRemoveProductButtonClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    removeProductByUiIdAndPositionId(product.uiId, product.positionId);
  };

  return (
    <>
      <div className="flex items-center gap-2 group">
        {
            errors.length > 0 && (
            <ErrorsTooltip errors={errors}>
              <FontAwesomeIcon icon={faExclamationCircle} className="text-red-500 h-5 w-5" />
            </ErrorsTooltip>
            )
        }
        {
          errors.length === 0 && warnings.length > 0 && (
            <WarningsTooltip warnings={warnings}>
              <FontAwesomeIcon icon={faTriangleExclamation} className="text-warning-500 h-5 w-5" />
            </WarningsTooltip>
          )
        }
        <OverflowEllipsisParagraph
          maxLines={1}
          isTooltipEnabled
        >
          <WrappedP
            fieldPath={productNameField.path}
            fieldId={product.positionId}
            isProductField
          >
            {product.name || product.product?.name}
          </WrappedP>
        </OverflowEllipsisParagraph>

        <Tooltip
          label="Highlight product"
        >
          <IconButtonWrappedP
            fieldPath={productNameField.path}
            fieldId={product.positionId}
            isProductField
          >
            <FontAwesomeIcon icon={faMagnifyingGlass} className="text-gray-300 hover:text-gray-500" />
          </IconButtonWrappedP>
        </Tooltip>

        <div className="flex gap-2">
          {
            isRecordingAvailable && (
            <Tooltip
              label={t('order.processOrderDrafts.rightSide.playAudio')}
            >
              <button
                type="button"
                className=""
                onClick={onPlayButtonClick}
              >
                <FontAwesomeIcon icon={faCirclePlay} className="text-gray-300 hover:text-gray-500" />
              </button>
            </Tooltip>
            )
        }
        </div>
      </div>

      <div className="flex items-center gap-2">
        <div className="!font-mono text-xs text-gray-500 relative">
          <div className={twMerge(
            'inset-0 bg-gray-200 rounded-sm',
            isPriceLoading ? 'absolute animate-pulse' : 'hidden',
          )}
          />
          <Tooltip
            label={t('order.processOrderDrafts.rightSide.pricePerUnit')}
            withArrow
          >
            <p className={twMerge(
              (isPriceLoading || !product.price || !product.price.amount) && 'invisible',
            )}
            >
              {formatPrice(product.price?.amount, product.price?.currency)}
            </p>
          </Tooltip>
        </div>
        <ConfidenceLevelIndicator score={product.llmScore} isMatchedByID={wasMatchedByID(product)} className="mr-2" />
        <Tooltip label={t('order.processOrderDrafts.rightSide.deleteProduct')}>
          <button
            type="button"
            aria-label={t('order.processOrderDrafts.rightSide.deleteProduct')}
            onClick={onRemoveProductButtonClick}
          >
            <FontAwesomeIcon icon={faTrashCan} className="text-red-500" />
          </button>
        </Tooltip>
      </div>
    </>
  );
};

export default ProductNameAndIcons;
