import { twMerge } from 'tailwind-merge';

import { faFileText, faImage } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from '@mantine/core';

import { isImageTabSelected, isTextAndRecordingTabSelected } from '../utils/tab';

interface Props {
  selectedTab: 'text-and-recording' | string;
  setSelectedTab: (tab: 'text-and-recording' | string) => void;
  handleImageTabClick: (index: number) => void;
  imageLength: number;
}

const FocusModeTab = ({
  selectedTab,
  setSelectedTab,
  handleImageTabClick,
  imageLength,
}: Props) => (
  <div
    className="flex gap-2 border-t border-b border-gray-400 border-opacity-70 py-2 overflow-x-auto min-h-fit"
  >
    <Button
      leftSection={<FontAwesomeIcon icon={faFileText} className="-mr-1" />}
      bg={isTextAndRecordingTabSelected(selectedTab) ? 'black' : 'gray.1'}
      c={isTextAndRecordingTabSelected(selectedTab) ? 'white' : 'black'}
      size="xs"
      hiddenFrom="4xl"
      onClick={() => setSelectedTab('text-and-recording')}
    >
      Text
    </Button>

    <Button
      leftSection={<FontAwesomeIcon icon={faFileText} className="-mr-1" />}
      bg={isTextAndRecordingTabSelected(selectedTab) ? 'black' : 'gray.1'}
      c={isTextAndRecordingTabSelected(selectedTab) ? 'white' : 'black'}
      size="sm"
      visibleFrom="4xl"
      onClick={() => setSelectedTab('text-and-recording')}
    >
      Text
    </Button>

    {
        Array.from({ length: imageLength }).map((_, index) => (
          <div key={`image-${index.toString()}`} className="relative">
            <Button
              leftSection={<FontAwesomeIcon icon={faImage} className="-mr-1" />}
              bg={isImageTabSelected(selectedTab, index) ? 'black' : 'gray.1'}
              c={isImageTabSelected(selectedTab, index) ? 'white' : 'black'}
              size="xs"
              hiddenFrom="4xl"
              onClick={() => handleImageTabClick(index)}
            >
              {index + 1}
            </Button>

            <Button
              leftSection={<FontAwesomeIcon icon={faImage} className="-mr-1" />}
              bg={isImageTabSelected(selectedTab, index) ? 'black' : 'gray.1'}
              c={isImageTabSelected(selectedTab, index) ? 'white' : 'black'}
              size="sm"
              visibleFrom="4xl"
              onClick={() => handleImageTabClick(index)}
              className={twMerge(
                // !hasImageTabBeenClicked && index === 0 && 'animate-pulsFast',
              )}
              style={{
                // border: !hasImageTabBeenClicked && index === 0 ? '1px solid black' : 'none',
              }}
            >
              {index + 1}
            </Button>
          </div>
        ))
    }
  </div>
);

export default FocusModeTab;
