import {
  memo,
  useCallback, useEffect, useMemo, useRef,
  useState,
} from 'react';

import { globalUser } from 'state/globalUser';

import { ProductWithQuantity } from 'features/order/models/Order';
import { Product } from 'features/product/models/Product';

import { ProductSortingColumn } from 'features/product/types/product';

import { useDebouncedState } from '@mantine/hooks';
import {
  QuerySearchType,
  useFetchProducts,
} from 'hooks/fetch/useFetchProducts';

import { Select, SelectOption } from 'components/ui/Select';
import { SelectRef } from 'components/ui/Select/Select';

import { formatProductOption, formatProductSelectedOption } from './utils';
import {
  wasMatchedByID,
} from 'features/product/utils/product';

import { useSettingsStore } from 'features/order/store/settingsStore';

type Params = {
  businessId: string;
  searchQuery?: string;
  useNameOrIDOrSKUSearch?: boolean;
  sortingColumn?: ProductSortingColumn;
  filterEnabledProducts?: boolean;
  reset?: boolean;
  includeOrderCount?: boolean;
};

interface Props {
  businessId: string;
  productWithQuantity: ProductWithQuantity;
  onProductChange: (product: Product) => void;
  label?: string;
  setError?: (error: string) => void;
}

const ProductSelect = memo(({
  businessId,
  label = '',
  productWithQuantity,
  onProductChange,
  setError,
}: Props) => {
  const sortingColumn = useSettingsStore((state) => state.sortingColumn);
  const filterEnabledProducts = useSettingsStore((state) => state.filterEnabledProducts);

  const {
    products, isLoading, loadProducts: loadProducts_, endReachedRef,
  } = useFetchProducts(
    { preventInitialFetch: true },
  );

  const selectRef = useRef<SelectRef>(null);

  const [dropdownPlaceHolder, setDropdownPlaceHolder] = useState(null);

  const wasMatchedByID_ = useMemo(() => wasMatchedByID(productWithQuantity), [productWithQuantity]);
  const selectedID = useMemo(() => productWithQuantity?.product?.id, [productWithQuantity]);

  const error = useMemo(() => {
    if (!productWithQuantity?.product) return 'Select a product';
    return '';
  }, [productWithQuantity?.product]);

  const [searchQuery, setSearchQuery] = useDebouncedState('', 300);

  const isFirstRender = useRef(true);
  const paramsRef = useRef<Params>({
    businessId,
    sortingColumn,
  });

  // Wrap loadProducts_ to scroll the dropdown into view after the products are loaded
  const loadProducts = useCallback((vals: any) => {
    loadProducts_(vals)
      .then(() => {
        selectRef.current?.scrollDropdownIntoView();
      });
  }, [loadProducts_]);

  useEffect(() => {
    paramsRef.current.sortingColumn = sortingColumn;
  }, [sortingColumn]);

  useEffect(() => {
    paramsRef.current.filterEnabledProducts = filterEnabledProducts;
  }, [filterEnabledProducts]);

  useEffect(() => {
    paramsRef.current.businessId = businessId;
  }, [businessId]);

  const selectedProduct = useMemo<SelectOption>(() => (
    formatProductSelectedOption(productWithQuantity, wasMatchedByID_)
  ), [productWithQuantity, wasMatchedByID_]);

  const productsOptions: SelectOption[] = useMemo(
    () => products.map((p) => formatProductOption(p, selectedID, wasMatchedByID_)),
    [products, selectedID, wasMatchedByID_],
  );

  const onProductsSelectChange = useCallback(
    (option: SelectOption) => {
      onProductChange(
        products.find((p) => p.id === option.value),
      );
    },
    [onProductChange, products],
  );

  const onProductsSearchChange = useCallback(
    (value: string) => {
      const searchValue: string = value || '';

      if (searchValue.length < 2) {
        setDropdownPlaceHolder('More than 2 characters are required to search');
        return;
      }

      if (!isLoading && searchValue !== '') {
        paramsRef.current.useNameOrIDOrSKUSearch = true;
        paramsRef.current.reset = true;
        setSearchQuery(searchValue);
      } else if (!isLoading && searchValue === '') {
        paramsRef.current.useNameOrIDOrSKUSearch = false;
        paramsRef.current.reset = true;
        setSearchQuery(productWithQuantity.name);
      }
    },
    [isLoading, productWithQuantity.name, setSearchQuery],
  );

  const onProductsSelectOptionsScrolledEnd = useCallback(
    (value: string) => {
      if (!isLoading && value !== '' && !endReachedRef.current) {
        loadProducts({
          searchQuery: value,
          customerId: paramsRef.current.businessId,
          querySearchType: QuerySearchType.Standard,
          sortingColumns: [paramsRef.current.sortingColumn],
          filterEnabledProducts: paramsRef.current.filterEnabledProducts,
          reset: false,
        });
      }
    },
    [endReachedRef, isLoading, loadProducts],
  );

  const onDropDownOpen = useCallback(() => {
    if (!isLoading && !products.length && productWithQuantity.name) {
      paramsRef.current.useNameOrIDOrSKUSearch = false;
      paramsRef.current.reset = false;
      paramsRef.current.includeOrderCount = true;
      setSearchQuery(productWithQuantity.name);
    }
  }, [isLoading, products.length, productWithQuantity.name, setSearchQuery]);

  useEffect(() => {
    if (isFirstRender.current || !paramsRef.current || !loadProducts) {
      isFirstRender.current = false;
      return;
    }

    loadProducts({
      searchQuery,
      supplierId: globalUser?.business?.id,
      customerId: paramsRef.current.businessId,
      querySearchType: paramsRef.current.useNameOrIDOrSKUSearch
        ? QuerySearchType.Standard
        : QuerySearchType.Embedding,
      sortingColumns: [paramsRef.current.sortingColumn],
      filterEnabledProducts: paramsRef.current.filterEnabledProducts,
      reset: paramsRef.current.reset,
      includeOrderCount: paramsRef.current.includeOrderCount,
    });
  }, [loadProducts, searchQuery]);

  useEffect(() => {
    setError(error);
  }, [error, setError]);

  return (
    <Select
      ref={selectRef}
      label={label}
      searchPlaceholder="Type here to search products..."
      dropdownPlaceHolder={dropdownPlaceHolder}
      selectedOption={selectedProduct}
      options={productsOptions}
      isLoading={isLoading}
      isExternalSearchEnabled
      onSelectionChange={onProductsSelectChange}
      onSearchPerformed={onProductsSearchChange}
      onScrolledEnd={onProductsSelectOptionsScrolledEnd}
      onDropdownOpen={onDropDownOpen}
      scrollOnDropdownOpen
      position="bottom"
      error={error}
      inputStyles={{
        error: {
          position: 'absolute',
        },
      }}
      middlewares={{ flip: false, shift: false }}
      shrinkVisibleId
    />
  );
});

export default ProductSelect;
