import { faComments, IconDefinition } from '@fortawesome/free-regular-svg-icons';
import {
  faArrowsRotate, faCartArrowDown, faEnvelope, faWrench,
} from '@fortawesome/free-solid-svg-icons';

import { AlertTheme } from 'state/classes/AlertDataList';

import { Message } from 'features/message/models/Message';
import { Order } from 'features/order/models/Order';
import { Event } from 'models/Event';
import { Operation } from 'models/Lro';

import { getDescriptionByGroup, getTitleByGroup } from 'features/lro/utils';

type DecodedEvent = {
  title: string;
  icon: IconDefinition;
  description?: string;
  theme: AlertTheme;
  orderId?: string;
  orderProcessedAlreadyOnce?: boolean;
  messageId?: string;
  threadId?: string;
};

const formatMessageTitle = (message: Message) => {
  const base = 'New message';
  const [userName, userEmail, userPhone] = [
    `${message.userSentByInfo?.firstName || ''} ${message.userSentByInfo?.lastName || ''}`.trim(),
    message.userSentByInfo?.email,
    message.userSentByInfo?.phone,
  ];
  const [bizName, bizEmail, bizPhone] = [
    message.businessSentByInfo?.name,
    message.businessSentByInfo?.emails?.[0],
    message.businessSentByInfo?.phones?.[0],
  ];

  const from = userName || userEmail || userPhone || bizName || bizEmail || bizPhone;
  return from ? `${base} from ${from}` : base;
};

export const decodeEvent = (event: Event): DecodedEvent => {
  switch (event.type) {
    case 'new_chat':
      return {
        title: 'New chat created',
        icon: faComments,
        theme: AlertTheme.INFO,
      };
    case 'new_order': {
      const order = event.getDataInstanceOf(Order);
      return {
        title: `New order placed by ${order.createdByUserEmail || order.createdByUserPhone}`,
        icon: faCartArrowDown,
        orderId: order.id,
        theme: AlertTheme.INFO,
      };
    }
    case 'new_message': {
      const message = event.getDataInstanceOf(Message);

      // If chat id exists
      if (message.chatId) {
        return { title: '', icon: null, theme: AlertTheme.INFO };
      }

      return {
        title: formatMessageTitle(message),
        icon: faEnvelope,
        messageId: message.id,
        threadId: message.threadId,
        theme: AlertTheme.INFO,
      };
    }
    // case 'classified_message': {
    //   const message = event.getDataInstanceOf(Message);
    //   return {
    //     title: `Message ${message.id} classified`,
    //     icon: faEnvelope,
    //     messageId: message.id,
    //     threadId: message.threadId,
    //   };
    // }
    // case 'processed_message': {
    //   const message = event.getDataInstanceOf(Message);
    //   return {
    //     title: `Order draft for message ${message.id} is ready`,
    //     icon: faEnvelope,
    //   };
    // }
    case 'failed_message': {
      const message = event.getDataInstanceOf(Message);
      return {
        title: 'Something went wrong while processing message',
        icon: faEnvelope,
        messageId: message.id,
        threadId: message.threadId,
        theme: AlertTheme.ERROR_ALERT,
      };
    }
    case 'eager_sync_success': {
      const order = event.getDataInstanceOf(Order);
      return {
        title: `Order ${order.externalId || order.id} synced successfully to ERP`,
        icon: faArrowsRotate,
        orderId: order.id,
        orderProcessedAlreadyOnce: true,
        theme: AlertTheme.SUCCESS,
      };
    }
    case 'eager_sync_failure': {
      const order = event.getDataInstanceOf(Order);
      return {
        title: `Order ${order.id} failed to sync to ERP`,
        icon: faArrowsRotate,
        orderId: order.id,
        orderProcessedAlreadyOnce: true,
        theme: AlertTheme.ERROR_ALERT,
      };
    }
    case 'smtp_message_failed': {
      const msg = event.getDataInstanceOf(Message);
      return {
        title: `Failed to send email ${msg?.id}`,
        icon: faEnvelope,
        messageId: msg?.id,
        threadId: msg?.threadId,
        theme: AlertTheme.ERROR_ALERT,
      };
    }
    case 'lro_started': {
      const operation = event.getDataInstanceOf(Operation);
      return {
        title: getTitleByGroup(operation.group, operation.type, operation.subGroup),
        icon: null,
        description: getDescriptionByGroup(operation.group, operation.type, operation.subGroup),
        theme: AlertTheme.INFO,
      };
    }
    case 'lro_done': {
      const lro = event.getDataInstanceOf(Operation);
      // Converts error truthy or falsy to boolean
      const isError = !!lro?.error;
      switch (lro?.group) {
        case 'workflow':
          if (isError) {
            return {
              title: 'Workflow run failed',
              icon: faWrench,
              theme: AlertTheme.ERROR_ALERT,
              description: getDescriptionByGroup(lro.group, lro.type, lro.subGroup),
            };
          }
          return {
            title: 'Workflow run completed',
            icon: faWrench,
            theme: AlertTheme.SUCCESS,
            description: getDescriptionByGroup(lro.group, lro.type, lro.subGroup),
          };
        default:
          if (isError) {
            return {
              title: 'Operation failed',
              icon: faWrench,
              theme: AlertTheme.ERROR_ALERT,
              description: getDescriptionByGroup(lro.group, lro.type, lro.subGroup, true),
            };
          }
          return {
            title: 'Operation completed',
            icon: faWrench,
            theme: AlertTheme.SUCCESS,
            description: getDescriptionByGroup(lro.group, lro.type, lro.subGroup, false, true),
          };
      }
    }
    case 'failed_order_sync':
      return {
        title: 'Failed to sync order to ERP',
        icon: faArrowsRotate,
        theme: AlertTheme.ERROR_ALERT,
      };
    default:
      break;
  }

  return { title: '', icon: null, theme: AlertTheme.INFO };
};
